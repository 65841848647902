import React, { useCallback, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDropzone } from 'react-dropzone';
import useComponentState from './../../utils/useComponentState';
import { uploadBulkOrder } from '../../api/Vouchers';
import Spinner from 'react-bootstrap/Spinner';

import './BulkPurchaseModal.scss';

const BulkPurchaseModal = ({ token, show, handleClose }) => {
  const [{ loading, uploadedFile, error, customClass, apiResponse }, setState] =
    useComponentState({
      loading: false,
      uploadedFile: null,
      error: '',
      customClass: '',
      apiResponse: '',
    });

  const handleModalClose = () => {
    handleClearError();
    handleClose();
  };

  const handleSubmit = async () => {
    handleClearError();
    if (acceptedFiles.length < 1 && fileRejections.length < 1) {
      setState({
        error: 'Please upload .csv to proceed',
        customClass: 'error',
      });
      return;
    }
    try {
      setState({ loading: true });
      await uploadBulkOrder(uploadedFile, token);
      setState({ apiResponse: 'Your bulk purchase request is successful!' });
    } catch (error) {
      setState({ apiResponse: 'Oops, something went wrong. Please try again' });
    }
    setState({ loading: false });
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setState({ uploadedFile: acceptedFiles[0] });
    if (fileRejections.length > 0 && acceptedFiles.length < 1) {
      setState({
        error:
          'You can only upload *.csv files, and the max no of files is restricted to 1',
        customClass: 'error',
      });
    } else if (acceptedFiles.length > 0) {
      setState({ error: '', customClass: 'success', apiResponse: '' });
    }
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,

    maxFiles: 1,
    accept: { 'application/csv': ['.csv'] },
  });

  const files = acceptedFiles.map((file) => (
    <div key={file.path} className='pt-1'>
      {file.path}
    </div>
  ));

  const handleClearError = () => {
    setState({
      error: '',
      customClass: '',
      apiResponse: '',
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => handleModalClose()}
      backdrop='static'
      size='md'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Purchase Bulk</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div {...getRootProps()} className={`dropzone__custom ${customClass}`}>
          <input {...getInputProps()} onClick={() => handleClearError()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop your .csv file, or click to select files</p>
          )}
        </div>
        <aside
          style={{
            color: error && acceptedFiles.length < 1 ? 'red' : '#208637',
            textAlign: error && acceptedFiles.length < 1 ? 'center' : '',
          }}
        >
          {error && acceptedFiles.length < 1 ? <small>{error}</small> : files}
        </aside>
        <div style={{ paddingTop: '1rem' }}>
          <Button
            variant='dark'
            block
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <Spinner animation='border' /> : 'Create Vouchers'}
          </Button>
          <div className='text-center'>
            <small style={{ color: 'red' }}>{apiResponse}</small>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BulkPurchaseModal;
