import React from 'react';
import { Form, Col } from 'react-bootstrap';

import styles from './SearchBySubMerchant.module.scss';

const SearchBySubMerchant = (props) => {
  const { subMerchants, onSelect } = props;

  const renderOptin = () => {
    return subMerchants?.map((subMerchant) => {
      return (
        <option value={subMerchant.id}>
          {subMerchant.location} ( {subMerchant.id} )
        </option>
      );
    });
  };
  return (
    <div>
      <Form.Row className='justify-content-lg-end'>
        <Col>
          <Form.Control
            className={styles.tsubmerchant_picker__control}
            as='select'
            onChange={(e) => onSelect(e.target.value)}
          >
            <option value='0'>{}All sub merchants</option>
            {renderOptin()}
          </Form.Control>
        </Col>
      </Form.Row>
    </div>
  );
};

export default SearchBySubMerchant;
