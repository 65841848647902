import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from '../viewEvents/ViewEvents.module.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Loading, PageHeader, Pagination } from '../../components';

import DataTable from '../../components/DataTable/DataTable';
import { getAllTerminals, saveNewPin } from '../../api/Terminal';
import { editEvent } from '../../api/events';
import CancelAlertPopup from '../../components/CancelAlertPopup/CancelAlertPopup';
import SearchByRedemtionIdOrReferanceCode from '../../components/SearchByRedemtionIdOrReferanceCode/SearchByRedemtionIdOrReferanceCode.component';
import { ButtonToolbar } from 'rsuite';

class ViewEventConditions extends Component {
  state = {
    tableheading: {
      header: 'EVENT CONDITIONS',
      column: [
        'Event Condition ID',
        'Condition Key',
        'Operand Type',
        'Condition Operator',
        'Parameter',
      ],
    },

    showModal: false,
    onClickId: 'T001',
    pin: '',
    tableData: [],
    slicedTableData: [],
    modalData: [],
    loading: false,
    fetchError: '',
    pinSavingError: '',
    pinSavingSuccessMsg: '',
    terminalPages: 0,
    current: 0,
    editable: false,
    validated: false,
    status: 'Active',
    conditionType: '',
    conditionKey: '',
    conditionOperator: '',
    parameter: '',
    tiersList: [],
    numberOfPointsOffered: null,
    description: '',
    pinType: 'password',
    rowData: null,
    cancelModalShow: false,
    btnClickable: false,
    pinError: null,
    canClearSearch: false,
    searchedMerchantName: '',
    notFoundMessage: null,
    changes: false,
    searchedTerminalId: '',
    data: [],
    operandTypes: ['NUMBER', 'TEXT', 'DATE_TIME', 'YES_NO', 'DECIMAL'],
    operatorData: [
      'EQUALS',
      'GREATER_THAN',
      'LESS_THAN',
      'BEFORE',
      'AFTER',
      'LIKE',
      'EQUALS_IGNORE_CASE',
      'IS_TRUE',
      'IS_FALSE',
      'IN',
      'REGEX',
      'MOD',
      'BETWEEN',
      'TIME_OF_DAY',
    ],
    eventNames: [],
    showEvents: false,
  };

  componentDidMount() {
    const {
      auth: { token },
    } = this.props;

    this.setState({
      loading: false,
    });
    this.getEventCondition(token);
  }

  getEventCondition = async (token) => {
    try {
      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/event-condition',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      this.setState({
        tableData: data.eventConditions,
      });
    } catch (err) {
      console.error('Error fetching tiers:', err);
      this.setState({
        tableData: [],
      });
    }
  };

  calculateTerminalPage = (data) => {
    var terminalPages = 0;
    var mod = data.length % 10;
    if (mod > 0) {
      terminalPages = data.length / 10 - mod / 10 + 1;
    } else {
      terminalPages = data.length / 10;
    }
    return terminalPages;
  };

  sortTerminals = (data) => {
    return data.sort((a, b) =>
      a.merchant.name.toLowerCase() > b.merchant.name.toLowerCase() ? 1 : -1
    );
  };

  handlePagination = (page) => {
    let sliceStart = 0;
    let sliceEnd = 10;
    if (page > 0) {
      sliceStart = page * 10;
      sliceEnd = (page + 1) * 10;
    }
    this.setState({
      slicedTableData: this.state.tableData.slice(sliceStart, sliceEnd),
    });
  };

  handleGetEvents = async (id) => {
    try {
      const response = await fetch(
        `https://dev-api.thyaga.xyz/v1.0/event-condition/${id}/events`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.props.auth.token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        this.setState({
          eventNames: Array.isArray(data) && data.length > 0 ? data : [],
        });
      } else {
        console.error('Failed to fetch events:', data);
        this.setState({
          eventNames: [],
        });
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      this.setState({
        eventNames: [],
      });
    }
  };

  handleModalShow = () => {
    this.setState({
      showModal: !this.state.showModal,
      editable: false,
      pinError: null,
      pinSavingSuccessMsg: '',
      pinSavingError: '',
      btnClickable: false,
      changes: false,
    });
  };

  handleCancelModalShow = () => {
    if (!this.state.changes) {
      this.setState({
        showModal: false,
        editable: false,
      });
    } else {
      this.setState({
        cancelModalShow: !this.state.cancelModalShow,
      });
    }
  };

  handleOnClick = (id) => {
    const rowData = this.state.tableData.find((data) => data.id === id);

    this.setState({
      showModal: true,
      onClickId: id,
      rowData,
      conditionKey: rowData.conditionKey || '',
      conditionType: rowData.conditionType || '',
      conditionOperator: rowData.operator || '',
      parameter: rowData.value || '',
    });

    this.handleGetEvents(id);
  };

  setModalFieldValues = (data) => {
    this.setState({
      name: data.name,
      eventCondition: data.eventCondition,
      triggeredAt: data.triggeredAt,
      tiersList: data.tiersList,
      numberOfPointsOffered: data.numberOfPointsOffered,
      description: data.additionalInfo,
    });
  };

  handlePinOnChange = (e) => {
    this.setState({
      pin: e.target.value.replace(/[^0-9\s]/g, '').trim(),
      btnClickable: true,
      pinError: null,
      changes: true,
    });
  };

  // handleOnChange = (e) => {
  //   const { name, value } = e.target;

  //   this.setState((prevState) => ({
  //     ...prevState,
  //     rowData: {
  //       ...prevState.rowData,
  //       [name]: value,
  //     },
  //     btnClickable: true,
  //     changes: true,
  //   }));
  // };

  handleEditable = (boolean) => {
    this.setState({
      editable: boolean,
      pinSavingSuccessMsg: '',
      validated: false,
    });
    if (!boolean) {
      this.setModalFieldValues(this.state.rowData);
    } else {
      this.setState({
        pin: '',
      });
    }
  };

  handleCancelModalYesBtnClick = () => {
    this.setModalFieldValues(this.state.rowData);
    this.handleCancelModalShow();
    this.handleModalShow();
  };

  handleEditEventCondition = async (event) => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true });
      return;
    }

    const payload = {
      id: this.state.onClickId,
      conditionKey: this.state.conditionKey,
      conditionType: this.state.conditionType,
      operator: this.state.conditionOperator,
      value: this.state.parameter,
    };

    console.log('Payload:', payload);

    try {
      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/event-condition/update',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.props.auth.token}`, // Include authorization if needed
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        this.setState({
          addOfferLoading: false,
          addOfferSuccess: true,
          addOfferError: null,
          selectedConditionKey: '',
          selectedOperandType: '',
          selectedConditionOperator: '',
          numberOfPointsOffered: 0,
        });
        alert('Event Updated successfully');
        window.location.href = '/event-conditions/';
      } else {
        throw new Error('Failed to update event condition.');
      }
    } catch (err) {
      this.setState({
        addOfferLoading: false,
        addOfferError: err.message,
      });
    }
  };

  handleStatusChange = (e) => {
    this.setState({
      status: e.target.value,
      btnClickable: true,
    });
  };

  onChangeMerchantNameChange = (e) => {
    this.setState({
      searchedMerchantName: e.target.value,
    });
  };

  handleSearchByMerchant = (event) => {
    event.preventDefault();
    const name = this.state.searchedMerchantName;
    if (name) {
      const data = this.state.Data.filter((item) => {
        let itemData = item.merchant.name.toLowerCase();
        return itemData.indexOf(name.toLowerCase()) > -1;
      });
      const sortList = this.sortTerminals(data);
      this.setState({
        tableData: sortList,
        loading: false,
        terminalPages: this.calculateTerminalPage(sortList),
        slicedTableData: sortList.slice(0, 10),
        canClearSearch: true,
        notFoundMessage: null,
      });
      if (data.length === 0) {
        this.setState({
          notFoundMessage: `No merchant found for name ${name}`,
        });
      }
    }
  };

  onClean = () => {
    this.setState({
      searchedMerchantName: '',
      loading: true,
    });
    this.getEvents(this.props.auth.token);
  };

  terminalIdOnChange = (e) => {
    this.setState({
      searchedTerminalId: e.target.value,
    });
  };

  onEventIdClean = () => {
    this.setState({
      searchedTerminalId: '',
    });
    this.getEvents(this.props.auth.token);
  };

  handleSearchByTerminalId = (event) => {
    event.preventDefault();
    const id = this.state.searchedTerminalId;
    if (id) {
      const data = this.state.Data.filter((item) => {
        let itemData = item.id.toLowerCase();
        return itemData.indexOf(id.toLowerCase()) > -1;
      });
      const sortList = this.sortTerminals(data);
      this.setState({
        tableData: sortList,
        loading: false,
        terminalPages: this.calculateTerminalPage(sortList),
        slicedTableData: sortList.slice(0, 10),
        canClearSearch: true,
        notFoundMessage: null,
      });
      if (data.length === 0) {
        this.setState({
          notFoundMessage: `No terminal found for terminal id ${id}`,
        });
      }
    }
  };

  renderStatusDropDown = (defaultValue) => {
    return (
      <Form.Group controlId='Customvalidation' as={Row}>
        <Col>
          <select
            id='x-select'
            class='form-control form-control-sm custom-select'
            onClick={this.handleStatusChange}
            name='status'
            disabled={!this.state.editable}
            defaultValue={defaultValue}
          >
            <option value='Active' key={0}>
              Active
            </option>
            <option value='Inactive' key={1}>
              Inactive
            </option>
          </select>
        </Col>
      </Form.Group>
    );
  };

  renderInputGroup = (type, name, value, onChange, max, onClick) => {
    return (
      <Form.Group>
        <InputGroup>
          <Form.Control
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            required
            maxLength={max}
            disabled={'triggeredAt' || !this.state.editable}
          />
          <InputGroup.Append onClick={onClick}>
            <InputGroup.Text className='password-toggle__span'>
              <i
                className={type === 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}
              ></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {this.state.pinError && (
          <small className='error_msg'>{this.state.pinError}</small>
        )}
      </Form.Group>
    );
  };

  renderField = (
    type,
    name,
    value,
    onChange,
    max,
    label,
    required,
    disabled = false
  ) => {
    return (
      <Form.Group>
        <Form.Control
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          maxLength={max}
          disabled={disabled} // Use the disabled parameter
          required={required}
          style={{ borderRadius: '15px' }}
        />
        <Form.Control.Feedback type='invalid' className='error_msg'>
          {label} cannot be empty
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  renderModalData = () => {
    const { conditionKey, conditionType, conditionOperator, parameter } =
      this.state;

    return (
      <>
        <tr>
          <td className={styles.fieldName}>Events using this Condition</td>
          <td>
            <div className={styles.conditionChipContainer}>
              {/* Conditionally display event names */}
              {this.state.showEvents &&
                this.state.eventNames &&
                this.state.eventNames.length > 0 &&
                this.state.eventNames.map((name, index) => (
                  <span
                    key={index}
                    style={{ marginRight: '8px' }}
                    className={styles.conditionChip}
                  >
                    {name}
                  </span>
                ))}

              {/* Fallback message if there are no events */}
              {this.state.showEvents &&
                this.state.eventNames &&
                this.state.eventNames.length === 0 && (
                  <span className={styles.conditionChip}>
                    No Event Using This Condition Yet
                  </span>
                )}

              <div className={styles.row}>
                {!this.state.showEvents && (
                  <span className={styles.conditionChip}>{`${
                    this.state.eventNames ? this.state.eventNames.length : 0
                  } events`}</span>
                )}
              </div>

              <div className={styles.row}>
                {this.state.eventNames && this.state.eventNames.length > 0 && (
                  <ButtonToolbar
                    appearance='link'
                    onClick={() =>
                      this.setState((prevState) => ({
                        showEvents: !prevState.showEvents,
                      }))
                    }
                    style={{
                      marginTop: '5px',
                      marginLeft: '2px',
                      color: '#210842',
                      cursor: 'pointer',
                      textDecoration: 'none', // Default state
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.textDecoration = 'underline')
                    }
                    onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
                  >
                    {this.state.showEvents ? 'Hide Events' : 'See Events'}
                  </ButtonToolbar>
                )}
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Condition Key</td>
          <td>
            {this.renderField(
              'text',
              'conditionKey',
              conditionKey, // Use state
              (e) =>
                this.setState({
                  conditionKey: e.target.value,
                  btnClickable: true,
                  changes: true,
                }),
              100,
              'Condition Key',
              true,
              true // Pass true to disable the field
            )}
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Operand Type</td>
          {/* <td>
            {this.renderField(
              'text',
              'conditionType',
              conditionType, // Use state
              (e) =>
                this.setState({
                  conditionType: e.target.value,
                  btnClickable: true,
                  changes: true,
                }),
              100,
              'Operand Type',
              true
            )}
          </td> */}
          <td>
            <Form.Group>
              <Form.Control
                as='select'
                value={conditionType} // Use state
                onChange={(e) =>
                  this.setState({
                    conditionType: e.target.value,
                    btnClickable: true,
                    changes: true,
                  })
                }
                style={{ borderRadius: '15px' }}
              >
                {this.state.operandTypes.map((operandType) => (
                  <option key={operandType} value={operandType}>
                    {operandType}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className={styles.fieldName}>Condition Operator</td>
          <td>
            <Form.Group>
              <Form.Control
                as='select'
                value={conditionOperator} // Use state
                onChange={(e) =>
                  this.setState({
                    conditionOperator: e.target.value,
                    btnClickable: true,
                    changes: true,
                  })
                }
                style={{ borderRadius: '15px' }}
              >
                {this.state.operatorData.map((operator) => (
                  <option key={operator} value={operator}>
                    {operator}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className={styles.fieldName}>Parameter</td>
          <td>
            {this.renderField(
              'text',
              'parameter',
              parameter, // Use state
              (e) =>
                this.setState({
                  parameter: e.target.value,
                  btnClickable: true,
                  changes: true,
                }),
              100,
              'Parameter',
              true
            )}
          </td>
        </tr>
      </>
    );
  };

  renderModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showModal}
          onHide={this.handleModalShow}
          backdrop='static'
          keyboard={false}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>
              Event Condition : {this.state.conditionKey}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={styles.edit_btn}>
              {/* <Button
                variant='danger'
                onClick={() => {
                  this.handleEditable(true);
                }}
              >
                Edit
              </Button> */}
            </div>
            <div className={styles.modal_root}>
              <Form
                noValidate
                validated={this.state.validated}
                onSubmit={this.handleEditEventCondition}
              >
                <Table borderless>
                  <tbody>{this.renderModalData()}</tbody>
                </Table>
                <div className={styles.modal_footer}>
                  <Button
                    variant='primary'
                    type='submit'
                    disabled={!this.state.btnClickable}
                    block
                    className={styles.footer_btn}
                    style={{
                      borderRadius: '15px',
                      backgroundColor: '#210842',
                      borderColor: '#fff',
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    variant='secondary'
                    onClick={this.handleCancelModalShow}
                    block
                    className={styles.footer_btn}
                    style={{ borderRadius: '15px' }}
                  >
                    Close
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer />
          <Modal.Body className={styles.modal_footer_msg}>
            {this.state.pinSavingSuccessMsg !== '' ? (
              <p className={styles.success_msg}>
                {this.state.pinSavingSuccessMsg}
              </p>
            ) : this.state.pinSavingError !== '' ? (
              <p className={styles.error_msg}>{this.state.pinSavingError}</p>
            ) : null}
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  renderLoading = () => {
    return <Loading text='Loading' />;
  };

  renderError = () => {
    return <div>{this.state.error}</div>;
  };

  render() {
    return (
      <div className={styles.root__div}>
        {this.state.loading ? (
          <Loading text='Loading' />
        ) : (
          <div className={styles.table}>
            <PageHeader text='Event Conditions' />
            <div className={styles.search_area}>
              <SearchByRedemtionIdOrReferanceCode
                onSubmit={this.handleSearchByMerchant}
                onChange={this.onChangeMerchantNameChange}
                value={this.state.searchedMerchantName}
                placeholder='Event Condition Name'
                isClearable={this.state.canClearSearch}
                onClean={this.onClean}
              />
              <div className={styles.separator} />
              <SearchByRedemtionIdOrReferanceCode
                onSubmit={this.handleSearchByTerminalId}
                onChange={this.terminalIdOnChange}
                value={this.state.searchedTerminalId}
                placeholder='Even Condition ID'
                onClean={this.onEventIdClean}
              />
              <div className={styles.separator} />
            </div>
            <div className={styles.table_div}>
              <DataTable
                tableData={this.state.tableData}
                tableheading={this.state.tableheading}
                handleOnClick={this.handleOnClick}
                tag='eventConditions'
                fetchError={this.state.fetchError}
                notFoundMessage={this.state.notFoundMessage}
              />
            </div>
          </div>
        )}

        {this.state.tableData.length > 10 && (
          <div className={styles.pager}>
            <Pagination
              pageCount={this.state.terminalPages}
              onChange={this.handlePagination}
              currentPage={this.state.current}
            />
          </div>
        )}

        {this.renderModal()}

        <CancelAlertPopup
          show={this.state.cancelModalShow}
          alertMessage='Your details will be lost. Do you wish to proceed?'
          handleYesBtnClick={this.handleCancelModalYesBtnClick}
          handleNoBtnClick={this.handleCancelModalShow}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewEventConditions);
