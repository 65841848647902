import { GET, POST } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/redemption/${endpoint}`;
};

export const getEarnings = (request, token) => {
  const endpoint = getEndpointWithPrefix(`getEarnings`);

  return POST(endpoint, request, token);
};
export const getEarningsByDate = (request, token) => {
  let query = `${request.subMerchantId}/searchbydate/?page=${request.page}&merchantId=${request.merchantId}`;

  if (request.startDate && request.endDate) {
    query = query.concat(
      `&startDate=${request.startDate}&endDate=${request.endDate}`
    );
  }

  if (request.size) {
    query = query.concat(`&size=${request.size}`);
  }

  const endpoint = getEndpointWithPrefix(query);
  return GET(endpoint, token);
};

export const getEarningsByRidRcode = (request, token) => {
  const endpoint = getEndpointWithPrefix('searchByRedemptionId');
  return POST(endpoint, request, token);
};

export const unredeem = (unredeem, token) => {
  const endpoint = getEndpointWithPrefix(`un-redeem`);
  return POST(endpoint, unredeem, token);
};

export const unredeemState = (redemptionId, token) => {
  const endpoint = getEndpointWithPrefix(
    `un-redeem-state/?redemptionId=${redemptionId}`
  );
  return GET(endpoint, token);
};

export const getRedemptionsByMerchant = (request, token) => {
  const endpoint = getEndpointWithPrefix('getRedemptionByMerchant');
  return POST(endpoint, request, token);
};

export const getEarningsBetweenDate = (merchantId, token, param) => {
  const endpoint = getEndpointWithPrefix(
    `${merchantId}/searchByDate/?page=${param.page}&startDate=${param.startDate}&endDate=${param.endDate}`
  );
  return GET(endpoint, token);
};

export const getEarningsByRid = (merchantId, token, param) => {
  const endpoint = getEndpointWithPrefix(
    `${merchantId}/searchByRedemptionId/?page=${param.page}&redemptionid=${param.redemptionid}&selectIdType=${param.selectIdType}`
  );
  return GET(endpoint, token);
};

export const getNotPaidRedemptionsByMerchant = (request, token) => {
  const endpoint = getEndpointWithPrefix('getRedemptionByMerchantForPayment');
  return POST(endpoint, request, token);
};

export const getAllRedemptionInExcelSheet = (id, token) => {
  const endpoint = getEndpointWithPrefix(`download/${id}`);
  return GET(endpoint, token);
};

export const getAllRedemptionByDateInExcelSheet = (
  token,
  startDate,
  endDate,
  id
) => {
  const endpoint = getEndpointWithPrefix(
    `download-by-date/?startDate=${startDate}&endDate=${endDate}&id=${id}`
  );
  return GET(endpoint, token);
};

export const getEarningsBetweenDateForPayments = (merchantId, token, param) => {
  const endpoint = getEndpointWithPrefix(
    `${merchantId}/for-payments/searchByDate/?page=${param.page}&startDate=${param.startDate}&endDate=${param.endDate}`
  );
  return GET(endpoint, token);
};
