import React, { useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Select from 'react-select';
import { MoonLoader } from 'react-spinners';
import { FaCheckCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import { getSubMerchantsByMerchant } from '../../api/subMerchant';
import { createOffer } from '../../api/offer';
import {
  AppInput,
  AppButton,
  DatePicker,
  OfferTypeSelect,
  PageHeader,
} from '../../components';
import useComponentState from '../../utils/useComponentState';
import offerTypes from '../../constants/offerTypes';
import { getAllMerchant } from '../../api/merchant';

import CancelAlertPopup from '../../components/CancelAlertPopup/CancelAlertPopup';

import styles from './AddOffer.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

const AddOffer = (props) => {
  const [state, setState] = useComponentState({
    addOfferLoading: false,
    addOfferError: null,
    addOfferSuccess: false,
    showCancelPopup: false,
    merchants: [],
    disable: true,
    merchantError: null,
    subMerchants: [],
    merchantId: null,
    subMerchantError: null,
  });

  const {
    auth: { token },
  } = props;
  const { addOfferLoading, addOfferError, addOfferSuccess } = state;

  const initialState = {
    merchant: null,
    subMerchantsForMerchant: [],
    subMerchants: [],
    title: '',
    code: '',
    imageUrl: '',
    description: '',
    termsAndConditions: '',
    platformContribution: 0,
    merchantContribution: 0,
    value: 0,
    unit: '',
    startDate: new Date(),
    endDate: new Date(),
    minimum: 0,
  };

  useEffect(() => {
    getMerchants();
    //eslint-disable-next-line
  }, []);

  const getMerchants = async () => {
    try {
      const response = await getAllMerchant(token);
      const sortList = sortMerchantList(response);
      setState({
        merchants: sortList,
        merchantId: sortList[0].id,
      });
      getSubMerchants(sortList[0].id);
    } catch (err) {
      setState({
        merchants: [],
      });
    }
  };

  const getSubMerchants = async (merchantId) => {
    try {
      const result = await getSubMerchantsByMerchant(merchantId, token);
      const sortList = sortSubMerchantList(result.subMerchants);
      const options = sortList.map(({ id, location }) => {
        return {
          value: id,
          label: location + ' ' + `(${id})`,
        };
      });
      setState({
        subMerchants: options,
      });
    } catch (err) {
      setState({ subMerchantError: 'Submerchant fetch failed' });
    }
  };

  const sortMerchantList = (data) => {
    return data.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  };

  const sortSubMerchantList = (data) => {
    return data.sort((a, b) =>
      a.location.toLowerCase() > b.location.toLowerCase() ? 1 : -1
    );
  };

  const handleAddOfferSubmit = async (
    { merchant, subMerchants, subMerchantsForMerchant, ...rest },
    { resetForm }
  ) => {
    try {
      const owner = subMerchants.map((subMerchant) => subMerchant.value);
      const body = {
        owner,
        ...rest,
        merchantId: state.merchantId,
      };
      if (state.merchantId) {
        setState({ addOfferLoading: true, addOfferError: null });
        await createOffer(body, token);
        setState({ addOfferLoading: false, addOfferSuccess: true });
        resetForm({ ...initialState });
        setTimeout(() => {
          setState({ addOfferSuccess: false });
        }, 2000);
      } else {
        setState({ merchantError: 'Merchant cannot be empty' });
      }
    } catch (err) {
      setState({
        addOfferLoading: false,
        addOfferSuccess: false,
        addOfferError: 'Failed to add offer',
      });
    }
  };

  const handleGetBranches = async (merchantId, setFieldValue) => {
    try {
      const result = await getSubMerchantsByMerchant(merchantId, token);
      const sortList = sortSubMerchantList(result.subMerchants);
      const options = sortList.map(({ id, location }) => {
        return {
          value: id,
          label: location + ' ' + `(${id})`,
        };
      });
      setFieldValue('subMerchantsForMerchant', options);
      setState({ subMerchants: options });
    } catch (err) {
      console.log(err);
    }
  };

  const handleMerchantOnChange = (e, setFieldValue) => {
    const id = e.target.value;
    setFieldValue('merchant', id);
    handleGetBranches(id, setFieldValue);
    setState({
      disable: false,
      merchantId: id,
    });
  };

  const handleNumberInputOnChange = (e, tag, setFieldValue) => {
    const value = e.target.value.slice(0, 19);
    const regex = /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (regex.test(value.toString())) {
      setFieldValue(tag, value);
    }
  };

  const renderLoading = () => {
    return (
      <div className={styles.message__div}>
        <MoonLoader size={30} color={'#3a195a'} />
        <span className={styles.message__text}>Loading</span>
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div className={styles.message__div}>
        <FaCheckCircle size={30} color={'#4da626'} />
        <span className={styles.message__text}>Offers added successfully</span>
      </div>
    );
  };

  const handlePopupState = () => {
    setState({
      showCancelPopup: !state.showCancelPopup,
    });
  };

  return (
    <div className={styles.root__div}>
      <Formik
        initialValues={initialState}
        validationSchema={Yup.object().shape({
          subMerchants: Yup.array()
            .nullable()
            .required('Sub merchant cannot be empty')
            .min(1, 'Sub merchant cannot be empty'),
          title: Yup.string().required('Offer title cannot be empty').trim(),
          code: Yup.string().required('Promo code cannot be empty').trim(),
          imageUrl: Yup.string().required('Image URL cannot be empty').trim(),
          description: Yup.string()
            .required('Offer description cannot be empty')
            .trim(),
          termsAndConditions: Yup.string()
            .required('Terms and Conditions cannot be empty')
            .trim(),
          platformContribution: Yup.number().required(
            'Platform contribution cannot be empty'
          ),
          merchantContribution: Yup.number().required(
            'Merchant contribution cannot be empty'
          ),
          value: Yup.number()
            .required('Offer value cannot be empty')
            .min(1, 'Offer value cannot be empty'),
          unit: Yup.string().required('Offer type cannot be empty'),
          startDate: Yup.date().required('Start date cannot be empty'),
          endDate: Yup.date()
            .required('End date cannot be empty')
            .min(Yup.ref('startDate'), 'End date cannot be before start date.'),
          minimum: Yup.number()
            .required('Minimum redemption limit cannot be empty')
            .min(1, 'Minimum redemption limit cannot be empty'),
        })}
        onSubmit={handleAddOfferSubmit}
      >
        {({ values, setFieldValue, setFieldTouched, resetForm }) => {
          return (
            <div className={styles.form__div}>
              {addOfferLoading ? (
                renderLoading()
              ) : addOfferSuccess ? (
                renderSuccess()
              ) : (
                <Form className={styles.add__form}>
                  <PageHeader text='Add Offer' />
                  <div className={styles.form__group}>
                    <span className={styles.form__label}>Merchant</span>
                    <select
                      id='x-select'
                      class='form-control form-control-sm custom-select'
                      onClick={(e) => {
                        handleMerchantOnChange(e, setFieldValue);
                      }}
                      name='merchants'
                    >
                      {state.merchants &&
                        state.merchants.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage name='merchants'>
                      {state.merchantError && (
                        <span className={styles.form__error}>
                          {state.merchantError}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className={styles.form__group}>
                    <span className={styles.form__label}>Sub Merchant</span>
                    <Select
                      isMulti
                      isDisabled={addOfferLoading}
                      value={values.subMerchants}
                      options={state.subMerchants}
                      onChange={(updated) => {
                        setFieldValue('subMerchants', updated);
                      }}
                      onBlur={() => {
                        setFieldTouched('subMerchants');
                      }}
                      placeholder='Select'
                    />
                    <ErrorMessage name='subMerchants'>
                      {(message) => (
                        <span className={styles.form__error}>{message}</span>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className={styles.horizontal__parent}>
                    <div className={styles.horizontal__child}>
                      <AppInput
                        name='title'
                        label='Offer Title'
                        type='text'
                        placeholder='Enter Offer Title'
                        loading={addOfferLoading}
                        max={50}
                      />
                    </div>
                    <div className={styles.horizontal__child}>
                      <AppInput
                        name='code'
                        label='Promo Code'
                        type='text'
                        placeholder='Enter Promo Code'
                        loading={addOfferLoading}
                        max={10}
                      />
                    </div>
                  </div>
                  <AppInput
                    name='imageUrl'
                    label='Image URL'
                    type='text'
                    placeholder='http://example.com/sample_image.png'
                    loading={addOfferLoading}
                    max={320}
                  />
                  <AppInput
                    name='termsAndConditions'
                    label='Terms and Conditions'
                    type='text'
                    placeholder='Enter Terms and Conditions'
                    loading={addOfferLoading}
                    max={255}
                  />
                  <AppInput
                    name='description'
                    label='Offer Description'
                    type='text'
                    placeholder='Enter Offer Description'
                    loading={addOfferLoading}
                    max={320}
                  />
                  <AppInput
                    name='value'
                    label='Offer Value'
                    type='number'
                    placeholder='Enter Offer Value'
                    loading={addOfferLoading}
                    onChange={(val) => {
                      handleNumberInputOnChange(val, 'value', setFieldValue);
                    }}
                  />
                  <AppInput
                    name='minimum'
                    label='Minimum Redemption Limit'
                    type='number'
                    placeholder='Enter Minimum Redemption Limit'
                    loading={addOfferLoading}
                    onChange={(val) => {
                      handleNumberInputOnChange(val, 'minimum', setFieldValue);
                    }}
                  />
                  <div className={styles.horizontal__parent}>
                    <div className={styles.horizontal__child}>
                      <AppInput
                        name='platformContribution'
                        label='Platform Contribution'
                        type='number'
                        placeholder='Enter Platform Contribution'
                        loading={addOfferLoading}
                        onChange={(val) => {
                          handleNumberInputOnChange(
                            val,
                            'platformContribution',
                            setFieldValue
                          );
                        }}
                      />
                    </div>
                    <div className={styles.horizontal__child}>
                      <AppInput
                        name='merchantContribution'
                        label='Merchant Contribution'
                        type='number'
                        placeholder='Enter Merchant Contribution'
                        loading={addOfferLoading}
                        onChange={(val) => {
                          handleNumberInputOnChange(
                            val,
                            'merchantContribution',
                            setFieldValue
                          );
                        }}
                      />
                    </div>
                  </div>
                  <OfferTypeSelect
                    selected={values.unit}
                    label='Offer Type'
                    options={offerTypes}
                    onSelect={setFieldValue}
                    name='unit'
                  />
                  <div className={styles.horizontal__parent}>
                    <div className={styles.horizontal__child}>
                      <DatePicker
                        value={values.startDate}
                        label='Start Date'
                        onChange={(date) => {
                          setFieldValue('startDate', date);
                        }}
                        name='startDate'
                        startDate={values.startDate}
                        endDate={values.endDate}
                        minDate={new Date()}
                        loading={addOfferLoading}
                      />
                    </div>
                    <div className={styles.horizontal__child}>
                      <DatePicker
                        value={values.endDate}
                        label='End Date'
                        onChange={(date) => {
                          setFieldValue('endDate', date);
                        }}
                        name='endDate'
                        startDate={values.startDate}
                        endDate={values.endDate}
                        minDate={values.startDate}
                        loading={addOfferLoading}
                      />
                    </div>
                  </div>
                  <div className={styles.btn_block}>
                    <AppButton
                      text='Cancel'
                      loading={state.disable}
                      containerStyle={{ width: '50%' }}
                      onClick={handlePopupState}
                    />
                    <AppButton
                      type='submit'
                      text='Add Offer'
                      loading={addOfferLoading}
                      containerStyle={{ width: '50%' }}
                    />
                  </div>
                  {addOfferError && (
                    <span className={styles.main__error}>{addOfferError}</span>
                  )}
                  <CancelAlertPopup
                    show={state.showCancelPopup}
                    alertMessage='Are you sure you want to cancel?'
                    handleYesBtnClick={() => resetForm(handlePopupState())}
                    handleNoBtnClick={handlePopupState}
                  />
                </Form>
              )}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOffer);
