import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { getMerchantCodes } from '../../api/app';
import useComponentState from '../../utils/useComponentState';

const MerchantCodesModal = (props) => {
  const [
    {
      fetchError,
      merchantCodesError,
      merchantCodesLoading,
      showMerchantCodes,
      merchantCodesDetails,
    },
    setState,
  ] = useComponentState({
    fetchError: '',
    merchantCodesError: '',
    merchantCodesLoading: false,
    showMerchantCodes: false,
    merchantCodesDetails: [],
  });

  const handleCloseModal = () => {
    setState({
      success: false,
      updatedExpiry: null,
      date: null,
      renderDatePicker: false,
      validated: false,
      loading: false,
      extendError: false,
    });
    props.handleModalClose();
  };

  useEffect(() => {
    if (props.showMerchantCodesModal) {
      getMerchantCodesByRedemptionId(props.token, props.redemptionId);
    }
  }, [props.showMerchantCodesModal]);

  const getMerchantCodesByRedemptionId = async () => {
    setState({
      merchantCodesLoading: true,
      merchantCodesError: '',
    });
    try {
      const request = {
        redemptionId: props.redemptionId,
      };
      const token = props.token;
      const { voucherCodeList } = await getMerchantCodes(request, token);

      const hasSerialNumber = voucherCodeList.some(
        (voucherCode) => voucherCode.serialNumber
      );

      setState({
        merchantCodesDetails: voucherCodeList,
        merchantCodesLoading: false,
      });
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  return (
    <Modal
      show={props.showMerchantCodesModal}
      onHide={() => handleCloseModal()}
      backdrop='static'
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Merchant Codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {merchantCodesLoading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: '200px' }}
          >
            <Spinner animation='border' />
          </div>
        ) : (
          <>
            {merchantCodesDetails.length === 0 ? (
              <p style={{ textAlign: 'center' }}>
                NO MERCHANT CODES FOUND...!!!
              </p>
            ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>CODE</th>
                    <th>AMOUNT</th>
                    <th>EXPIRY DATE</th>
                    <th>SERIAL NUMBER</th>
                  </tr>
                </thead>
                <tbody>
                  {merchantCodesDetails.map((code, index) => (
                    <tr key={index}>
                      <td>{code.voucherCode}</td>
                      <td>{code.denomination}</td>
                      <td>{code.expiry}</td>
                      {code.serialNumber && <td>{code.serialNumber}</td>}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </>
        )}
        {merchantCodesError && (
          <Row className='mt-3'>
            <Col>
              <div className='alert alert-danger' role='alert'>
                {merchantCodesError}
              </div>
            </Col>
          </Row>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant='dark' onClick={props.handlemodalclose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MerchantCodesModal;
