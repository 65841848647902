import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Spinner from 'react-bootstrap/Spinner';

const VoucherActivationModal = (props) => {
  const renderInputField = (onChange, value) => {
    return (
      <Row>
        <Col sm>
          <Form.Group>
            <Form.Control
              type='text'
              name='name'
              placeholder='Name'
              required
              maxLength={70}
              onChange={onChange}
              value={value}
            />
            <Form.Control.Feedback type='invalid'>
              Name can't be empty
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    );
  };

  const renderMobileField = (value, onChange, validated, mobileError) => {
    return (
      <Row>
        <Col sm>
          <Form.Group>
            <PhoneInput
              required
              maxLength={15}
              defaultCountry={'LK'}
              value={value}
              placeholder='Mobile Number'
              onChange={onChange}
              className={
                validated
                  ? mobileError
                    ? 'border border-danger'
                    : 'border-success'
                  : 'border'
              }
            />
            {mobileError && validated && (
              <small className='text-danger'>{mobileError}</small>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      show={props.showActivationModal}
      //show={true}
      onHide={() => {
        props.handleClose();
      }}
      backdrop='static'
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Activate Voucher</Modal.Title>
      </Modal.Header>

      {!props.activationSuccess && (
        <Modal.Body>
          <div style={{ textAlign: 'center', margin: '0 auto 1rem' }}>
            <span>
              Activate Voucher <strong>{props.voucherId}</strong>
            </span>
          </div>
          <Form
            validated={props.validated}
            noValidate
            onSubmit={props.handleSubmit}
          >
            {renderInputField(props.handleReceiverChange, props.receiverName)}
            {renderMobileField(
              props.receiverMobile,
              props.handleMobileChange,
              props.validated,
              props.mobileError
            )}

            <Button
              variant='dark'
              type='submit'
              block /* style={{marginTop: '0.5rem'}} */
              disabled={props.loading}
            >
              {props.loading ? <Spinner animation='border' /> : 'ACTIVATE'}
            </Button>
            <div style={{ textAlign: 'center' }}>
              <small className='text-danger'>{props.activationError}</small>
            </div>
          </Form>
        </Modal.Body>
      )}

      {props.activationSuccess && (
        <Modal.Body>
          <div style={{ textAlign: 'center', margin: '0 auto 1rem' }}>
            <span>
              Voucher <strong>{props.voucherId}</strong> has been activated
            </span>
          </div>
          <Button variant='dark' onClick={() => props.handleClose()} block>
            OKAY
          </Button>
        </Modal.Body>
      )}

      {/* <div className='successMessage'>
        {props.addIsSuccess && (
          <span className='success_msg'>{props.successMessage}</span>
        )}
      </div> */}
    </Modal>
  );
};

export default VoucherActivationModal;
