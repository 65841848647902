import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ViewTerminals.module.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Loading, PageHeader, Pagination } from '../../components';

import DataTable from '../../components/DataTable/DataTable';
import { getAllTerminals, saveNewPin } from '../../api/Terminal';
import CancelAlertPopup from '../../components/CancelAlertPopup/CancelAlertPopup';
import SearchByRedemtionIdOrReferanceCode from '../../components/SearchByRedemtionIdOrReferanceCode/SearchByRedemtionIdOrReferanceCode.component';

class ViewTerminals extends Component {
  state = {
    tableheading: {
      header: 'TERMINALS',
      column: ['ID', 'Merchant', 'Sub Merchant', 'Status'],
    },

    showModal: false,
    onClickId: 'T001',
    pin: '',
    tableData: [],
    slicedTableData: [],
    modalData: [],
    loading: false,
    fetchError: '',
    pinSavingError: '',
    pinSavingSuccessMsg: '',
    terminalPages: 0,
    current: 0,
    editable: false,
    validated: false,
    status: 'Active',
    subMerchantId: '',
    model: '',
    description: '',
    pinType: 'password',
    rowData: null,
    cancelModalShow: false,
    btnClickable: false,
    pinError: null,
    canClearSearch: false,
    searchedMerchantName: '',
    notFoundMessage: null,
    changes: false,
    searchedTerminalId: '',
    data: [],
  };

  componentDidMount() {
    const {
      auth: { token },
    } = this.props;

    this.setState({
      loading: true,
    });
    this.getTerminals(token);
  }

  getTerminals = async (token) => {
    try {
      this.setState({ loading: true });
      const response = await getAllTerminals(token);
      const sortList = this.sortTerminals(response);
      this.setState({
        tableData: sortList,
        loading: false,
        terminalPages: this.calculateTerminalPage(sortList),
        slicedTableData: sortList.slice(0, 10),
        notFoundMessage: null,
        Data: sortList,
      });
    } catch (err) {
      this.setState({
        loading: false,
        fetchError: err,
      });
    }
  };

  calculateTerminalPage = (data) => {
    var terminalPages = 0;
    var mod = data.length % 10;
    if (mod > 0) {
      terminalPages = data.length / 10 - mod / 10 + 1;
    } else {
      terminalPages = data.length / 10;
    }
    return terminalPages;
  };

  sortTerminals = (data) => {
    return data.sort((a, b) =>
      a.merchant.name.toLowerCase() > b.merchant.name.toLowerCase() ? 1 : -1
    );
  };

  handlePagination = (page) => {
    let sliceStart = 0;
    let sliceEnd = 10;
    if (page > 0) {
      sliceStart = page * 10;
      sliceEnd = (page + 1) * 10;
    }
    this.setState({
      slicedTableData: this.state.tableData.slice(sliceStart, sliceEnd),
    });
  };

  handleModalShow = () => {
    this.setState({
      showModal: !this.state.showModal,
      editable: false,
      pinError: null,
      pinSavingSuccessMsg: '',
      pinSavingError: '',
      btnClickable: false,
      changes: false,
    });
  };

  handleCancelModalShow = () => {
    if (!this.state.changes) {
      this.setState({
        showModal: false,
        editable: false,
      });
    } else {
      this.setState({
        cancelModalShow: !this.state.cancelModalShow,
      });
    }
  };

  handleOnClick = (id) => {
    const rowData = this.state.tableData.find((data) => data.id === id);
    this.setState({
      showModal: true,
      onClickId: id,
      rowData,
    });
    this.setModalFieldValues(rowData);
  };

  setModalFieldValues = (data) => {
    this.setState({
      pin: data.pin,
      status: data.lastActive,
      subMerchantId: data.subMerchant.id,
      model: data.model,
      description: data.additionalInfo,
    });
  };

  handlePinOnChange = (e) => {
    this.setState({
      pin: e.target.value.replace(/[^0-9\s]/g, '').trim(),
      btnClickable: true,
      pinError: null,
      changes: true,
    });
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: this.state[name].length === 0 ? value.trim() : value,
      btnClickable: true,
      changes: true,
    });
  };

  handleEditable = (boolean) => {
    this.setState({
      editable: boolean,
      pinSavingSuccessMsg: '',
      validated: false,
    });
    if (!boolean) {
      this.setModalFieldValues(this.state.rowData);
    } else {
      this.setState({
        pin: '',
      });
    }
  };

  handlePinTypeChange = () => {
    if (this.state.pinType === 'password') {
      this.setState({
        pinType: 'text',
      });
    } else {
      this.setState({
        pinType: 'password',
      });
    }
  };

  handleCancelModalYesBtnClick = () => {
    this.setModalFieldValues(this.state.rowData);
    this.handleCancelModalShow();
    this.handleModalShow();
  };

  handlePinSave = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      validated: true,
    });

    try {
      const request = {
        pin: this.state.pin,
        terminalId: this.state.onClickId,
        subMerchant: this.state.subMerchantId,
        model: this.state.model,
        description: this.state.description,
        status: this.state.status,
      };
      const {
        auth: { token },
      } = this.props;

      if (request.pin.length === 0) {
        this.setState({
          pinError: 'Pin cannot be empty',
        });
        return;
      }
      await saveNewPin(request, token);
      this.setState({
        pinSavingSuccessMsg: 'Successfully updated',
        editable: false,
        btnClickable: false,
      });
      this.getTerminals(token);
    } catch (err) {
      this.setState({
        pinSavingError: 'Failed To Save',
      });
    }
  };

  handleStatusChange = (e) => {
    this.setState({
      status: e.target.value,
      btnClickable: true,
    });
  };

  onChangeMerchantNameChange = (e) => {
    this.setState({
      searchedMerchantName: e.target.value,
    });
  };

  handleSearchByMerchant = (event) => {
    event.preventDefault();
    const name = this.state.searchedMerchantName;
    if (name) {
      const data = this.state.Data.filter((item) => {
        let itemData = item.merchant.name.toLowerCase();
        return itemData.indexOf(name.toLowerCase()) > -1;
      });
      const sortList = this.sortTerminals(data);
      this.setState({
        tableData: sortList,
        loading: false,
        terminalPages: this.calculateTerminalPage(sortList),
        slicedTableData: sortList.slice(0, 10),
        canClearSearch: true,
        notFoundMessage: null,
      });
      if (data.length === 0) {
        this.setState({
          notFoundMessage: `No merchant found for name ${name}`,
        });
      }
    }
  };

  onClean = () => {
    this.setState({
      searchedMerchantName: '',
      loading: true,
    });
    this.getTerminals(this.props.auth.token);
  };

  terminalIdOnChange = (e) => {
    this.setState({
      searchedTerminalId: e.target.value,
    });
  };

  onTerminalIdClean = () => {
    this.setState({
      searchedTerminalId: '',
    });
    this.getTerminals(this.props.auth.token);
  };

  handleSearchByTerminalId = (event) => {
    event.preventDefault();
    const id = this.state.searchedTerminalId;
    if (id) {
      const data = this.state.Data.filter((item) => {
        let itemData = item.id.toLowerCase();
        return itemData.indexOf(id.toLowerCase()) > -1;
      });
      const sortList = this.sortTerminals(data);
      this.setState({
        tableData: sortList,
        loading: false,
        terminalPages: this.calculateTerminalPage(sortList),
        slicedTableData: sortList.slice(0, 10),
        canClearSearch: true,
        notFoundMessage: null,
      });
      if (data.length === 0) {
        this.setState({
          notFoundMessage: `No terminal found for terminal id ${id}`,
        });
      }
    }
  };

  renderField = (type, name, value, onChange, max, label, required) => {
    return (
      <Form.Group>
        <Form.Control
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          maxLength={max}
          disabled={name === 'subMerchantId' || !this.state.editable}
          required={required}
        />
        <Form.Control.Feedback type='invalid' className='error_msg'>
          {label} cannot be empty
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  renderStatusDropDown = (defaultValue) => {
    return (
      <Form.Group controlId='Customvalidation' as={Row}>
        <Col>
          <select
            id='x-select'
            class='form-control form-control-sm custom-select'
            onClick={this.handleStatusChange}
            name='status'
            disabled={!this.state.editable}
            defaultValue={defaultValue}
          >
            <option value='Active' key={0}>
              Active
            </option>
            <option value='Inactive' key={1}>
              Inactive
            </option>
          </select>
        </Col>
      </Form.Group>
    );
  };

  renderInputGroup = (type, name, value, onChange, max, onClick) => {
    return (
      <Form.Group>
        <InputGroup>
          <Form.Control
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            required
            maxLength={max}
            disabled={!this.state.editable}
          />
          <InputGroup.Append onClick={onClick}>
            <InputGroup.Text className='password-toggle__span'>
              <i
                className={type === 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}
              ></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {this.state.pinError && (
          <small className='error_msg'>{this.state.pinError}</small>
        )}
      </Form.Group>
    );
  };

  renderModalData = () => {
    const data = this.state;
    return (
      <>
        <tr>
          <td className={styles.fieldName}>Sub Merchant</td>
          <td>
            {this.renderField(
              'text',
              'subMerchantId',
              data.subMerchantId,
              this.handleOnChange,
              50,
              'Sub Merchant',
              true
            )}
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Model</td>
          <td>
            {this.renderField(
              'text',
              'model',
              data.model,
              this.handleOnChange,
              10,
              'Model',
              false
            )}
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Description</td>
          <td>
            {this.renderField(
              'text',
              'description',
              data.description,
              this.handleOnChange,
              100,
              'Description',
              false
            )}
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Status</td>
          <td>{this.renderStatusDropDown(data.status)}</td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Pin</td>
          <td>
            {this.renderInputGroup(
              this.state.pinType,
              'pin',
              data.pin,
              this.handlePinOnChange,
              5,
              this.handlePinTypeChange,
              true
            )}
          </td>
        </tr>
      </>
    );
  };

  renderModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showModal}
          onHide={this.handleModalShow}
          backdrop='static'
          keyboard={false}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>ID : {this.state.onClickId}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={styles.edit_btn}>
              <Button
                variant='danger'
                onClick={() => {
                  this.handleEditable(true);
                }}
              >
                Edit
              </Button>
            </div>
            <div className={styles.modal_root}>
              <Form
                noValidate
                validated={this.state.validated}
                onSubmit={this.handlePinSave}
              >
                <Table borderless>
                  <tbody>{this.renderModalData()}</tbody>
                </Table>
                <div className={styles.modal_footer}>
                  <Button
                    variant='secondary'
                    onClick={this.handleCancelModalShow}
                    block
                    className={styles.footer_btn}
                  >
                    Close
                  </Button>

                  <Button
                    variant='primary'
                    type='submit'
                    disabled={!this.state.btnClickable}
                    block
                    className={styles.footer_btn}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer />
          <Modal.Body className={styles.modal_footer_msg}>
            {this.state.pinSavingSuccessMsg !== '' ? (
              <p className={styles.success_msg}>
                {this.state.pinSavingSuccessMsg}
              </p>
            ) : this.state.pinSavingError !== '' ? (
              <p className={styles.error_msg}>{this.state.pinSavingError}</p>
            ) : null}
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  renderLoading = () => {
    return <Loading text='Loading' />;
  };

  renderError = () => {
    return <div>{this.state.error}</div>;
  };

  render() {
    return (
      <div className={styles.root__div}>
        {this.state.loading ? (
          <Loading text='Loading' />
        ) : (
          <div className={styles.table}>
            <PageHeader text='Terminals' />
            <div className={styles.search_area}>
              <SearchByRedemtionIdOrReferanceCode
                onSubmit={this.handleSearchByMerchant}
                onChange={this.onChangeMerchantNameChange}
                value={this.state.searchedMerchantName}
                placeholder='Merchant Name'
                isClearable={this.state.canClearSearch}
                onClean={this.onClean}
              />
              <div className={styles.separator} />
              <SearchByRedemtionIdOrReferanceCode
                onSubmit={this.handleSearchByTerminalId}
                onChange={this.terminalIdOnChange}
                value={this.state.searchedTerminalId}
                placeholder='Terminal ID'
                onClean={this.onTerminalIdClean}
              />
              <div className={styles.separator} />
            </div>
            <div className={styles.table_div}>
              <DataTable
                tableData={this.state.slicedTableData}
                tableheading={this.state.tableheading}
                handleOnClick={this.handleOnClick}
                tag='terminal'
                fetchError={this.state.fetchError}
                notFoundMessage={this.state.notFoundMessage}
              />
            </div>
          </div>
        )}

        {this.state.tableData.length > 10 && (
          <div className={styles.pager}>
            <Pagination
              pageCount={this.state.terminalPages}
              onChange={this.handlePagination}
              currentPage={this.state.current}
            />
          </div>
        )}

        {this.renderModal()}

        <CancelAlertPopup
          show={this.state.cancelModalShow}
          alertMessage='Your details will be lost. Do you wish to proceed?'
          handleYesBtnClick={this.handleCancelModalYesBtnClick}
          handleNoBtnClick={this.handleCancelModalShow}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTerminals);
