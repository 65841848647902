import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from '../viewEvents/ViewEvents.module.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Loading, PageHeader, Pagination } from '../../components';

import DataTable from '../../components/DataTable/DataTable';
import { getAllTerminals, saveNewPin } from '../../api/Terminal';
import { editEvent } from '../../api/events';
import CancelAlertPopup from '../../components/CancelAlertPopup/CancelAlertPopup';
import SearchByRedemtionIdOrReferanceCode from '../../components/SearchByRedemtionIdOrReferanceCode/SearchByRedemtionIdOrReferanceCode.component';

class ViewExecutionLog extends Component {
  state = {
    tableheading: {
      header: 'EVENT CONDITIONS',
      column: [
        'Event Execution ID',
        'Point Account',
        'Event Name',
        'Result Details',
        'End Time',
      ],
    },

    showModal: false,
    onClickId: 'T001',
    pin: '',
    tableData: [],
    slicedTableData: [],
    modalData: [],
    loading: false,
    fetchError: '',
    pinSavingError: '',
    pinSavingSuccessMsg: '',
    terminalPages: 0,
    current: 0,
    editable: false,
    validated: false,
    status: 'Active',
    conditionType: '',
    conditionKey: '',
    conditionOperator: '',
    parameter: '',
    tiersList: [],
    numberOfPointsOffered: null,
    description: '',
    pinType: 'password',
    rowData: null,
    cancelModalShow: false,
    btnClickable: false,
    pinError: null,
    canClearSearch: false,
    searchedMerchantName: '',
    notFoundMessage: null,
    changes: false,
    searchedTerminalId: '',
    data: [],
    operatorData: [
      'EQUALS',
      'GREATER_THAN',
      'LESS_THAN',
      'BEFORE',
      'AFTER',
      'LIKE',
      'EQUALS_IGNORE_CASE',
      'IS_TRUE',
      'IS_FALSE',
      'IN',
      'REGEX',
      'MOD',
      'BETWEEN',
      'TIME_OF_DAY',
    ],
  };

  componentDidMount() {
    const {
      auth: { token },
    } = this.props;

    this.setState({
      loading: false,
    });
    this.getExecLogs(token);
  }

  getExecLogs = async (token) => {
    try {
      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/event-execution/executions',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      this.setState({
        tableData: data,
      });
    } catch (err) {
      console.error('Error fetching tiers:', err);
      this.setState({
        tableData: [],
      });
    }
  };

  calculateTerminalPage = (data) => {
    var terminalPages = 0;
    var mod = data.length % 10;
    if (mod > 0) {
      terminalPages = data.length / 10 - mod / 10 + 1;
    } else {
      terminalPages = data.length / 10;
    }
    return terminalPages;
  };

  sortTerminals = (data) => {
    return data.sort((a, b) =>
      a.merchant.name.toLowerCase() > b.merchant.name.toLowerCase() ? 1 : -1
    );
  };

  handlePagination = (page) => {
    let sliceStart = 0;
    let sliceEnd = 10;
    if (page > 0) {
      sliceStart = page * 10;
      sliceEnd = (page + 1) * 10;
    }
    this.setState({
      slicedTableData: this.state.tableData.slice(sliceStart, sliceEnd),
    });
  };

  handleModalShow = () => {
    this.setState({
      showModal: !this.state.showModal,
      editable: false,
      pinError: null,
      pinSavingSuccessMsg: '',
      pinSavingError: '',
      btnClickable: false,
      changes: false,
    });
  };

  handleCancelModalShow = () => {
    if (!this.state.changes) {
      this.setState({
        showModal: false,
        editable: false,
      });
    } else {
      this.setState({
        cancelModalShow: !this.state.cancelModalShow,
      });
    }
  };

  handleOnClick = (id) => {
    const rowData = this.state.tableData.find((data) => data.id === id);
    this.setState({
      showModal: true,
      onClickId: id,
      rowData,
    });
    this.setModalFieldValues(rowData);
  };

  setModalFieldValues = (data) => {
    this.setState({
      name: data.name,
      eventCondition: data.eventCondition,
      triggeredAt: data.triggeredAt,
      tiersList: data.tiersList,
      numberOfPointsOffered: data.numberOfPointsOffered,
      description: data.additionalInfo,
    });
  };

  handlePinOnChange = (e) => {
    this.setState({
      pin: e.target.value.replace(/[^0-9\s]/g, '').trim(),
      btnClickable: true,
      pinError: null,
      changes: true,
    });
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: this.state[name].length === 0 ? value.trim() : value,
      btnClickable: true,
      changes: true,
    });
  };

  handleEditable = (boolean) => {
    this.setState({
      editable: boolean,
      pinSavingSuccessMsg: '',
      validated: false,
    });
    if (!boolean) {
      this.setModalFieldValues(this.state.rowData);
    } else {
      this.setState({
        pin: '',
      });
    }
  };

  handleCancelModalYesBtnClick = () => {
    this.setModalFieldValues(this.state.rowData);
    this.handleCancelModalShow();
    this.handleModalShow();
  };

  handleEditEvent = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      validated: true,
    });

    try {
      const request = {
        id: this.state.onClickId,
        name: this.state.name,
        eventCondition: this.state.eventCondition,
        triggeredAt: this.state.triggeredAt,
        tiersList: this.state.tiersList,
        numberOfPointsOffered: this.state.numberOfPointsOffered,
        description: this.state.description,
      };
      const {
        auth: { token },
      } = this.props;

      if (request.pin.length === 0) {
        this.setState({
          pinError: 'Pin cannot be empty',
        });
        return;
      }
      await editEvent(request, token);
      this.setState({
        pinSavingSuccessMsg: 'Successfully updated',
        editable: false,
        btnClickable: false,
      });
      this.getEvents(token);
    } catch (err) {
      this.setState({
        pinSavingError: 'Failed To Save',
      });
    }
  };

  handleStatusChange = (e) => {
    this.setState({
      status: e.target.value,
      btnClickable: true,
    });
  };

  onChangeMerchantNameChange = (e) => {
    this.setState({
      searchedMerchantName: e.target.value,
    });
  };

  handleSearchByMerchant = (event) => {
    event.preventDefault();
    const name = this.state.searchedMerchantName;
    if (name) {
      const data = this.state.Data.filter((item) => {
        let itemData = item.merchant.name.toLowerCase();
        return itemData.indexOf(name.toLowerCase()) > -1;
      });
      const sortList = this.sortTerminals(data);
      this.setState({
        tableData: sortList,
        loading: false,
        terminalPages: this.calculateTerminalPage(sortList),
        slicedTableData: sortList.slice(0, 10),
        canClearSearch: true,
        notFoundMessage: null,
      });
      if (data.length === 0) {
        this.setState({
          notFoundMessage: `No merchant found for name ${name}`,
        });
      }
    }
  };

  onClean = () => {
    this.setState({
      searchedMerchantName: '',
      loading: true,
    });
    this.getEvents(this.props.auth.token);
  };

  terminalIdOnChange = (e) => {
    this.setState({
      searchedTerminalId: e.target.value,
    });
  };

  onEventIdClean = () => {
    this.setState({
      searchedTerminalId: '',
    });
    this.getEvents(this.props.auth.token);
  };

  handleSearchByTerminalId = (event) => {
    event.preventDefault();
    const id = this.state.searchedTerminalId;
    if (id) {
      const data = this.state.Data.filter((item) => {
        let itemData = item.id.toLowerCase();
        return itemData.indexOf(id.toLowerCase()) > -1;
      });
      const sortList = this.sortTerminals(data);
      this.setState({
        tableData: sortList,
        loading: false,
        terminalPages: this.calculateTerminalPage(sortList),
        slicedTableData: sortList.slice(0, 10),
        canClearSearch: true,
        notFoundMessage: null,
      });
      if (data.length === 0) {
        this.setState({
          notFoundMessage: `No terminal found for terminal id ${id}`,
        });
      }
    }
  };

  renderField = (type, name, value, onChange, max, label, required) => {
    return (
      <Form.Group>
        <Form.Control
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          maxLength={max}
          disabled={name === !this.state.editable}
          required={required}
          style={{ borderRadius: '15px' }}
        />
        <Form.Control.Feedback type='invalid' className='error_msg'>
          {label} cannot be empty
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  renderStatusDropDown = (defaultValue) => {
    return (
      <Form.Group controlId='Customvalidation' as={Row}>
        <Col>
          <select
            id='x-select'
            class='form-control form-control-sm custom-select'
            onClick={this.handleStatusChange}
            name='status'
            disabled={!this.state.editable}
            defaultValue={defaultValue}
          >
            <option value='Active' key={0}>
              Active
            </option>
            <option value='Inactive' key={1}>
              Inactive
            </option>
          </select>
        </Col>
      </Form.Group>
    );
  };

  renderInputGroup = (type, name, value, onChange, max, onClick) => {
    return (
      <Form.Group>
        <InputGroup>
          <Form.Control
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            required
            maxLength={max}
            disabled={'triggeredAt' || !this.state.editable}
          />
          <InputGroup.Append onClick={onClick}>
            <InputGroup.Text className='password-toggle__span'>
              <i
                className={type === 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}
              ></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {this.state.pinError && (
          <small className='error_msg'>{this.state.pinError}</small>
        )}
      </Form.Group>
    );
  };

  renderModalData = () => {
    const { rowData } = this.state;

    if (!rowData) {
      return null; // No data to display
    }

    return (
      <>
        <tr>
          <td className={styles.fieldName}>Condition Key</td>
          <td>
            {this.renderField(
              'text',
              'conditionKey',
              rowData.conditionKey,
              this.handleOnChange,
              100,
              'Condition Key',
              true
            )}
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Operand Type</td>
          <td>
            {this.renderField(
              'text',
              'conditionType',
              rowData.conditionType,
              this.handleOnChange,
              100,
              'Operand Type',
              true
            )}
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Condition Operator</td>
          <td>
            <Form.Group>
              <Form.Control
                style={{ borderRadius: '15px' }}
                as='select'
                defaultValue={rowData.operator}
                onChange={(e) => {
                  const selectedOperand = this.state.operatorData.find(
                    (operand) => operand === e.target.value
                  );
                }}
              >
                <option value=''>Add more tiers</option>
                {this.state.operatorData.map((operand) => (
                  <option key={operand} value={operand}>
                    {operand}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {/* {this.renderField(
              'text',
              'conditionOperator',
              rowData.operator || '',
              this.handleOnChange,
              100,
              'Condition Operator',
              true
            )} */}
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Parameter</td>
          <td>
            {this.renderField(
              'text',
              'parameter',
              rowData.value || '',
              this.handleOnChange,
              100,
              'Parameter',
              true
            )}
          </td>
        </tr>
      </>
    );
  };

  renderModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showModal}
          onHide={this.handleModalShow}
          backdrop='static'
          keyboard={false}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>
              Event Condition ID : {this.state.onClickId}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={styles.edit_btn}>
              {/* <Button
                variant='danger'
                onClick={() => {
                  this.handleEditable(true);
                }}
              >
                Edit
              </Button> */}
            </div>
            <div className={styles.modal_root}>
              <Form
                noValidate
                validated={this.state.validated}
                onSubmit={this.handleEditEvent}
              >
                <Table borderless>
                  <tbody>{this.renderModalData()}</tbody>
                </Table>
                <div className={styles.modal_footer}>
                  <Button
                    variant='primary'
                    type='submit'
                    disabled={!this.state.btnClickable}
                    block
                    className={styles.footer_btn}
                    style={{
                      borderRadius: '15px',
                      backgroundColor: '#210842',
                      borderColor: '#fff',
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    variant='secondary'
                    onClick={this.handleCancelModalShow}
                    block
                    className={styles.footer_btn}
                    style={{ borderRadius: '15px' }}
                  >
                    Close
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer />
          <Modal.Body className={styles.modal_footer_msg}>
            {this.state.pinSavingSuccessMsg !== '' ? (
              <p className={styles.success_msg}>
                {this.state.pinSavingSuccessMsg}
              </p>
            ) : this.state.pinSavingError !== '' ? (
              <p className={styles.error_msg}>{this.state.pinSavingError}</p>
            ) : null}
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  renderLoading = () => {
    return <Loading text='Loading' />;
  };

  renderError = () => {
    return <div>{this.state.error}</div>;
  };

  render() {
    return (
      <div className={styles.root__div}>
        {this.state.loading ? (
          <Loading text='Loading' />
        ) : (
          <div className={styles.table}>
            <PageHeader text='Event Conditions' />
            <div className={styles.search_area}>
              <SearchByRedemtionIdOrReferanceCode
                onSubmit={this.handleSearchByMerchant}
                onChange={this.onChangeMerchantNameChange}
                value={this.state.searchedMerchantName}
                placeholder='Event Name'
                isClearable={this.state.canClearSearch}
                onClean={this.onClean}
              />
              <div className={styles.separator} />
              <SearchByRedemtionIdOrReferanceCode
                onSubmit={this.handleSearchByTerminalId}
                onChange={this.terminalIdOnChange}
                value={this.state.searchedTerminalId}
                placeholder='Even Execution ID'
                onClean={this.onEventIdClean}
              />
              <div className={styles.separator} />
            </div>
            <div className={styles.table_div}>
              <DataTable
                tableData={this.state.tableData}
                tableheading={this.state.tableheading}
                tag='executionLogs'
                fetchError={this.state.fetchError}
                notFoundMessage={this.state.notFoundMessage}
              />
            </div>
          </div>
        )}

        {this.state.tableData.length > 10 && (
          <div className={styles.pager}>
            <Pagination
              pageCount={this.state.terminalPages}
              onChange={this.handlePagination}
              currentPage={this.state.current}
            />
          </div>
        )}

        {this.renderModal()}

        <CancelAlertPopup
          show={this.state.cancelModalShow}
          alertMessage='Your details will be lost. Do you wish to proceed?'
          handleYesBtnClick={this.handleCancelModalYesBtnClick}
          handleNoBtnClick={this.handleCancelModalShow}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewExecutionLog);
