import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { AppInput, AppButton, PageHeader } from '../../components';
import styles from '../addEvents/AddEvent.module.scss';

const CONDITION_OPERATORS = [
  'EQUALS',
  'GREATER_THAN',
  'LESS_THAN',
  'BEFORE',
  'AFTER',
  'LIKE',
  'EQUALS_IGNORE_CASE',
  'IS_TRUE',
  'IS_FALSE',
  'IN',
  'REGEX',
  'MOD',
  'BETWEEN',
  'TIME_OF_DAY',
];

const OPERAND_TYPES = [
  'LONG',
  'STRING',
  'DATE_TIME',
  'BOOLEAN',
  'DOUBLE',
  'LIST',
];

const AddEventConditions = (props) => {
  const [state, setState] = useState({
    addOfferLoading: false,
    addOfferError: null,
    addOfferSuccess: false,
    selectedConditionOperator: '',
    selectedOperandType: '',
    selectedConditionKey: '',
    numberOfPointsOffered: 0,
  });

  const handleAddEventConditionSubmit = async () => {
    const token = props.auth.token; // Assuming token is stored in auth state
    try {
      const payload = {
        operator: state.selectedConditionOperator,
        conditionType: state.selectedOperandType,
        conditionKey: state.selectedConditionKey,
        value: state.numberOfPointsOffered,
      };

      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/event-condition/add',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setState({
          addOfferLoading: false,
          addOfferSuccess: true,
          addOfferError: null,
          selectedConditionOperator: '',
          selectedOperandType: '',
          selectedConditionKey: '',
          numberOfPointsOffered: 0,
        });
        alert('Event added successfully');
        window.location.href = '/event-conditions';
      }
    } catch (error) {
      console.error('Error creating event condition:', error);
      setState((prevState) => ({ ...prevState, addOfferError: error.message }));
    }
  };

  return (
    <div className={styles.root__div}>
      <Formik
        initialValues={{
          eventConditionOperator: '',
          operandType: '',
          conditionKey: '',
          numberOfPointsOffered: 0,
        }}
        validationSchema={Yup.object().shape({
          eventConditionOperator: Yup.string().required(
            'Select a Condition Operator'
          ),
          operandType: Yup.string().required('Select an Operand Type'),
          conditionKey: Yup.string().required('Condition Key cannot be empty'),
        })}
        onSubmit={handleAddEventConditionSubmit}
      >
        {({ setFieldValue, errors }) => (
          <Form
            className={styles.add__form}
            style={{
              margin: '20px',
              padding: '20px',
              border: '1px solid #ccc',
              borderRadius: '10px',
            }}
          >
            <PageHeader text='Add Event Condition' />

            <div
              className={styles.form__group}
              style={{ marginBottom: '15px' }}
            >
              <span
                className={styles.form__label}
                style={{ marginBottom: '5px', display: 'block' }}
              >
                Condition Key
              </span>
              <AppInput
                name='conditionKey'
                type='text'
                value={state.selectedConditionKey}
                placeholder='Enter Condition Key'
                style={{ borderRadius: '15px', width: '100%' }}
                onChange={(e) => {
                  setFieldValue('conditionKey', e.target.value);
                  setState({ ...state, selectedConditionKey: e.target.value });
                }}
              />
              {!errors.conditionKey && (
                <ErrorMessage name='conditionKey'>
                  {(msg) => <span className={styles.form__error}>{msg}</span>}
                </ErrorMessage>
              )}
            </div>

            <div
              className={styles.form__group}
              style={{ marginBottom: '15px' }}
            >
              <span
                className={styles.form__label}
                style={{ marginBottom: '5px', display: 'block' }}
              >
                Condition Operator
              </span>
              <select
                className='form-control form-control-sm custom-select'
                onChange={(e) => {
                  setFieldValue('eventConditionOperator', e.target.value);
                  setState({
                    ...state,
                    selectedConditionOperator: e.target.value,
                  });
                }}
                value={state.selectedConditionOperator || ''}
                style={{ borderRadius: '15px', width: '100%' }}
              >
                <option value='' disabled>
                  Select a Condition Operator
                </option>
                {CONDITION_OPERATORS.map((operator, index) => (
                  <option key={index} value={operator}>
                    {operator}
                  </option>
                ))}
              </select>
              {!errors.eventConditionOperator && (
                <ErrorMessage name='eventConditionOperator'>
                  {(msg) => <span className={styles.form__error}>{msg}</span>}
                </ErrorMessage>
              )}
            </div>

            <div
              className={styles.form__group}
              style={{ marginBottom: '15px' }}
            >
              <span
                className={styles.form__label}
                style={{ marginBottom: '5px', display: 'block' }}
              >
                Operand Type
              </span>
              <select
                className='form-control form-control-sm custom-select'
                onChange={(e) => {
                  setFieldValue('operandType', e.target.value);
                  setState({ ...state, selectedOperandType: e.target.value });
                }}
                value={state.selectedOperandType || ''}
                style={{ borderRadius: '15px', width: '100%' }}
              >
                <option value='' disabled>
                  Select an Operand Type
                </option>
                {OPERAND_TYPES.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              {!errors.operandType && (
                <ErrorMessage name='operandType'>
                  {(msg) => <span className={styles.form__error}>{msg}</span>}
                </ErrorMessage>
              )}
            </div>

            <AppInput
              name='numberOfPointsOffered'
              label='Parameter'
              type='string'
              value={state.numberOfPointsOffered}
              placeholder='Enter Parameter Value'
              style={{
                borderRadius: '15px',

                width: '100%',
                marginBottom: '15px',
              }}
              onChange={(e) => {
                setFieldValue('numberOfPointsOffered', e.target.value);
                setState({ ...state, numberOfPointsOffered: e.target.value });
              }}
            />
            {!errors.numberOfPointsOffered && (
              <ErrorMessage name='numberOfPointsOffered'>
                {(msg) => <span className={styles.form__error}>{msg}</span>}
              </ErrorMessage>
            )}

            <div
              className={styles.btn_block}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
              }}
            >
              <AppButton
                type='button'
                text='Cancel'
                containerStyle={{ width: '50%' }}
                onClick={() => {
                  setState({
                    selectedConditionOperator: '',
                    selectedOperandType: '',
                    selectedConditionKey: '',
                    numberOfPointsOffered: 0,
                  });
                }}
              />
              <AppButton
                type='submit'
                text='Add Event Condition'
                loading={state.addOfferLoading}
                containerStyle={{ width: '50%' }}
              />
            </div>
            {state.addOfferError && (
              <span
                className={styles.main__error}
                style={{ marginTop: '10px', color: 'red' }}
              >
                {state.addOfferError}
              </span>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(AddEventConditions);
