import React, { useEffect } from 'react';
import { PageHeader } from '../../components';
import { Card, Table } from 'react-bootstrap';
import { Loading } from '../../components';
import { connect } from 'react-redux';
import useComponentState from '../../utils/useComponentState';
import { getDenominationsSummary } from '../../api/merchantVoucher';

import './DenominationsSummary.scss';

const DenominationsSummary = (props) => {
  const [state, setState] = useComponentState({
    stores: [{ key: 'ALL', label: 'All' }],
    selectedStoreKey: 'ALL',
    tableHeading: {
      column: ['ID', 'Merchant', 'Denomination', 'Quantity'],
    },
    loading: false,
    fetchedData: [],
    tableData: [],
    fetchError: '',
  });
  const {
    stores,
    selectedStoreKey,
    loading,
    tableData,
    fetchError,
    tableHeading,
    notFoundMessage,
    fetchedData,
  } = state;

  useEffect(() => {
    getDenominations();
  }, []);

  const handleOnBlockChange = (key) => {
    setState({ selectedStoreKey: key });
    if (key === 'ALL') {
      getDenominations();
    } else {
      const filteredData = fetchedData.filter((data) => {
        return data.merchantVoucherId === key;
      });
      setState({ tableData: filteredData });
    }
  };

  const appendStores = (response) => {
    let fetchedStores = stores;
    response.forEach((denomination) => {
      const { merchantVoucherId, merchantVoucherName } = denomination;
      if (!stores.some((store) => store.key === merchantVoucherId)) {
        fetchedStores.push({
          key: merchantVoucherId,
          label: merchantVoucherName,
        });
      }
    });
    return fetchedStores;
  };

  const getDenominations = async () => {
    try {
      setState({ loading: true });
      const token = props.auth.auth.token;
      const response = await getDenominationsSummary(token);
      const fetchedStores = appendStores(response);
      setState({
        stores: fetchedStores,
        fetchedData: response,
        tableData: response,
        loading: false,
        notFoundMessage:
          !response || response.length < 1 ? 'No denominations found' : '',
      });
    } catch (err) {
      setState({
        fetchError: 'Failed to fetch purchases',
        loading: false,
        tableData: [],
      });
    }
  };

  const getToFixed = (amount) => {
    return amount
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const renderTableData = () => {
    return (
      <Card className='table_div'>
        <div>
          <Table responsive hover>
            <thead className='table_head'>
              {tableHeading.column.map((item, index) => {
                return <th key={index}>{item}</th>;
              })}
            </thead>
            <tbody>
              {notFoundMessage ? (
                <td colSpan={6} className='text-center'>
                  {notFoundMessage}
                </td>
              ) : loading ? (
                <td colSpan={6} className='text-center'>
                  <Loading text='Loading' />
                </td>
              ) : (
                tableData.map((data) => {
                  return (
                    <tr
                      bordered={false}
                      className='table_row'
                      /* onClick={() => {
                        this.handleOnClick(data);
                      }} */
                    >
                      <td>{data.id}</td>
                      <td>{data.merchantVoucherName}</td>
                      <td>{getToFixed(data.denomination)}</td>
                      <td>{data.availableQuantity}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </Card>
    );
  };

  const renderStoreBlocks = () => {
    return stores.map((item) => {
      const { key, label } = item;
      return (
        <Card
          className={
            selectedStoreKey !== key ? 'filter_card' : 'filter_card_active'
          }
          id={key}
          onClick={() => {
            handleOnBlockChange(key);
          }}
        >
          <Card.Body>
            <Card.Text>{label}</Card.Text>
          </Card.Body>
        </Card>
      );
    });
  };
  return (
    <div className='root__div'>
      <div className='root__div_main'>
        <PageHeader text='Gift Inventory' />
        <div className='filter_block'>{renderStoreBlocks()}</div>
        {renderTableData()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(DenominationsSummary);
