import React from 'react';
import moment from 'moment';

import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './DataTable.module.scss';

const DataTable = ({
  tableData,
  tableheading,
  handleOnClick,
  tag,
  fetchError,
  search_filter_loading,
  notFoundMessage,
  merchant,
  renderTerminalsDropdown,
}) => {
  const renderTableHeadings = () => {
    return tableheading.column.map((data, index) => {
      return (
        <th key={index} className={styles.tableHead} align='center'>
          {data}
        </th>
      );
    });
  };

  const renderTableData = () => {
    switch (tag) {
      case 'terminal':
        return tableData.map((data) => {
          return renderTerminalData(data);
        });
      case 'events':
        return tableData.map((data) => {
          return renderEventsData(data);
        });
      case 'eventConditions':
        return tableData.map((data) => {
          return renderEventConditionData(data);
        });
      case 'executionLogs':
        return tableData.map((data) => {
          return renderEventExecutionData(data);
        });
      case 'payments':
        return tableData.map((data) => {
          return renderPaymentData(data);
        });
      case 'merchants':
        return tableData.map((data) => {
          return renderMerchantData(data);
        });
      case 'subMerchants':
        return tableData.map((data) => {
          return renderSubMerchantData(data);
        });
      case 'redemptions':
        return tableData.map((data) => {
          return renderRedemptionsData(data);
        });
      case 'userDetails':
        return tableData.map((data) => {
          return renderUserDetails(data);
        });
      case 'voucherDetails':
        return tableData.map((data) => {
          return renderVoucherDetails(data);
        });
      case 'rewardRatios':
        return tableData.map((data) => {
          return renderRewardRatios(data);
        });
      case 'tierMultipliers':
        return tableData.map((data) => {
          return renderTierMultipliers(data);
        });
      case 'transactionDetails':
        return tableData.map((data) => {
          return renderTransactionHistory(data);
        });
      default:
        return;
    }
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    } else {
      return str.substring(0, num) + '...';
    }
  };

  const renderTransactionHistory = (data) => {
    return (
      <tr
        onClick={() => handleOnClick(data.redemptionId)}
        key={data.redemptionId}
      >
        <td>{moment(data.timestamp).format('DD/MM/YYYY hh:mm A')}</td>
        <td>{truncateString(data.description, 25)}</td>
        <td>{truncateString(data.transactionType, 25)}</td>
        <td>{truncateString(data.amount?.toFixed(2).toString(), 10)}</td>
      </tr>
    );
  };

  const renderUserDetails = (data) => {
    return (
      <tr key={data.email}>
        <td>{data.firstName}</td>
        <td>{data.lastName}</td>
        <td>{data.email}</td>
        <td>{data.mobileNo}</td>
      </tr>
    );
  };

  const renderVoucherDetails = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)} key={data.id}>
        <td>{data.id}</td>
        <td>{data.ownerName}</td>
        <td>{data.sender}</td>
        <td>
          {data.amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </td>
        <td>{data.ownerMobile}</td>
      </tr>
    );
  };

  const renderRedemptionsData = (data) => {
    return (
      <tr key={data.id}>
        <td>{data.id}</td>
        <td>
          {data.merchantEarning &&
            data.merchantEarning
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </td>
      </tr>
    );
  };

  const renderSubMerchantData = (data) => {
    return (
      <tr key={data.id}>
        <td>{data.id}</td>
        <td>{data.emails.join(', ')}</td>
        <td>{data.mobileNumber.join(', ')}</td>
        <td>{data.location}</td>
        <td style={{ width: '10px' }}>
          {renderTerminalsDropdown(data.terminals)}
        </td>
      </tr>
    );
  };

  const renderMerchantData = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)} key={data.id}>
        <td>{data.id}</td>
        <td>{data.name}</td>
        <td>{data.category}</td>
        <td>{data.status}</td>
        <td>{data.website}</td>
        <td>{data.tel}</td>
      </tr>
    );
  };

  const renderRewardRatios = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)} key={data.id}>
        <td>{data.name}</td>
        <td>{data.description}</td>
        <td>{data.minPoint}</td>
        <td>{data.maxPoint}</td>
        <td>{data.accumulationRatio}</td>
        <td>{data.redemptionRatio}</td>
      </tr>
    );
  };

  const renderTierMultipliers = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)} key={data.id}>
        <td>{data.id}</td>
        <td>{data.tier}</td>
        <td>{data.multiplier}</td>
      </tr>
    );
  };

  const renderTerminalData = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)} key={data.id}>
        <td>{data.id}</td>
        <td>{data.merchant.name}</td>
        <td>{data.subMerchant.id}</td>
        <td>{data.lastActive}</td>
      </tr>
    );
  };

  const renderEventsData = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.eventId)} key={data.eventId}>
        <td>{data.eventId}</td>
        <td>{data.name}</td>
        <td>{data.description}</td>
        {/* <td>{data.triggeredAt}</td> */}
        <td>{data.numberOfPointsOffered}</td>
      </tr>
    );
  };

  const renderEventConditionData = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)} key={data.id}>
        <td>{data.id}</td>
        <td>{data.conditionKey}</td>
        <td>{data.conditionType}</td>
        <td>{data.operator}</td>
        <td>{data.value}</td>
      </tr>
    );
  };
  const renderEventExecutionData = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)} key={data.id}>
        <td>{data.eventExecutionId}</td>
        <td>{data.pointAccountId}</td>
        <td>{data.eventName}</td>
        <td>{data.resultDetails}</td>
        <td>{data.endTime}</td>
      </tr>
    );
  };

  const renderPaymentData = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)} key={data.id}>
        <td>{moment(data.createdTimestamp).format('DD/MM/YYYY hh:mm A')}</td>
        {!merchant && <td>{data.id}</td>}
        <td>{data.referenceCode}</td>
        {!merchant && <td>{data.coreMerchant.name}</td>}
        <td>
          <span className='d-flex justify-content-end w-50'>
            {data.amount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </span>
        </td>
      </tr>
    );
  };

  const renderNotFound = (content) => {
    return (
      <tr>
        <td
          colSpan={tableheading.column && tableheading.column.length}
          className='text-secondary bg-white text-center'
        >
          <span className='py-4'>{content}</span>
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <div className='w-100'>
        <Table striped bordered hover>
          <thead>
            <tr>{renderTableHeadings()}</tr>
          </thead>
          <tbody>
            {search_filter_loading ? (
              <tr>
                <td
                  colSpan={tableheading.column && tableheading.column.length}
                  className='text-secondary bg-white text-center'
                >
                  <Spinner animation='border' />
                </td>
              </tr>
            ) : fetchError === '' && tableData.length > 0 ? (
              renderTableData()
            ) : fetchError ? (
              renderNotFound(fetchError)
            ) : (
              renderNotFound(`NO ${tag.toUpperCase()} FOUND`)
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  return <div className={styles.root__div}>{renderTable()}</div>;
};

export default DataTable;
