export const POST = (endpoint, requestBody = {}, authorization = null) => {
  const url = createRequestUrl(endpoint);
  const body = createRequestBody(requestBody);
  const headers = createRequestHeader(authorization);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });
      if (response.status >= 400) {
        const data = await response.json();
        reject(data);
      }
      const contentType = response.headers.get('Content-Type');
      if (contentType.includes('application/octet-stream')) {
        resolve(response.arrayBuffer());
      } else {
        const data = await response.json();
        resolve(data);
      }
    } catch (err) {
      if (
        err.message &&
        err.message === 'NetworkError when attempting to fetch resource.'
      ) {
        err.message = 'Network Error. Please try again.';
      } else if (err.message && err.message === 'SERVER_ERROR') {
        err.message = 'Could not connect to server. Please try again.';
      }
      reject(err);
    }
  });
};

export const GET = (endpoint, authorization = null) => {
  const url = createRequestUrl(endpoint);
  const headers = createRequestHeader(authorization);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });
      const data = await response.json();
      if (response.status >= 400) {
        reject(data);
      }
      resolve(data);
    } catch (err) {
      if (
        err.message &&
        err.message === 'NetworkError when attempting to fetch resource.'
      ) {
        err.message = 'Network Error. Please try again.';
      } else if (err.message && err.message === 'SERVER_ERROR') {
        err.message = 'Could not connect to server. Please try again.';
      }
      reject(err);
    }
  });
};

export const PUT = (endpoint, requestBody = {}, authorization) => {
  const url = createRequestUrl(endpoint);
  const body = createRequestBody(requestBody);
  const headers = createRequestHeader(authorization);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers,
        body,
      });
      const data = await response.json();
      if (response.status >= 400) {
        reject(data);
      }
      resolve(data);
    } catch (err) {
      if (
        err.message &&
        err.message === 'NetworkError when attempting to fetch resource.'
      ) {
        err.message = 'Network Error. Please try again.';
      } else if (err.message && err.message === 'SERVER_ERROR') {
        err.message = 'Could not connect to server. Please try again.';
      }
      reject(err);
    }
  });
};

export const DELETE = (endpoint, requestBody = {}, authorization) => {
  const url = createRequestUrl(endpoint);
  const body = createRequestBody(requestBody);
  const headers = createRequestHeader(authorization);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers,
        body,
      });
      const data = await response.json();
      if (response.status >= 400) {
        reject(data);
      }
      resolve(data);
    } catch (err) {
      if (
        err.message &&
        err.message === 'NetworkError when attempting to fetch resource.'
      ) {
        err.message = 'Network Error. Please try again.';
      } else if (err.message && err.message === 'SERVER_ERROR') {
        err.message = 'Could not connect to server. Please try again.';
      }
      reject(err);
    }
  });
};

const createRequestUrl = (endpoint) => {
  return `${process.env.REACT_APP_MERCHANT_PORTAL_API}/v1.0/app${endpoint}`;
};

const createRequestBody = (requestBody) => {
  return JSON.stringify(requestBody);
};

const createRequestHeader = (authorization) => {
  return authorization
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorization}`,
      }
    : {
        'Content-Type': 'application/json',
      };
};

export const POST_FORM_DATA = (
  endpoint,
  uploadedFile,
  authorization = null
) => {
  const url = createRequestUrl(endpoint);
  const headers = {
    // 'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${authorization}`,
  };

  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append('uploadFile', uploadedFile);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: formData,
      });
      const data = await response.json();
      if (response.status >= 400) {
        reject(data);
      }
      resolve(data);
    } catch (err) {
      if (
        err.message &&
        err.message === 'NetworkError when attempting to fetch resource.'
      ) {
        err.message = 'Network Error. Please try again.';
      } else if (err.message && err.message === 'SERVER_ERROR') {
        err.message = 'Could not connect to server. Please try again.';
      }
      reject(err);
    }
  });
};
