import AuthorizedComponent from '../components/hoc/AuthorizedComponent';
import {
  AddEvents,
  AddOffer,
  AddTerminal,
  ContactUs,
  Dashboard,
  ViewEarnings,
  ViewEvents,
  ViewOffers,
  ViewPayments,
  ViewTerminals,
  AddPayment,
  AddMerchant,
  Home,
  ViewMerchants,
  ViewPurchases,
  PendingPurchases,
  ViewVouchers,
  OTPLogs,
  IPLogs,
  ManageRatios,
  WorkInProgress,
  AddEventConditions,
  ViewEventConditions,
  DenominationsSummary,
} from '../screens';
import UserDetails from '../screens/userDetails/userDetails.screen';
import ViewExecutionLogScreen from '../screens/viewExecutionLog/ViewExecutionLog.screen';
import { EarningsRoles } from '../types/RedemptionEnums';

export const acceptOfferRole = ['ROLE_THYAGA_ADMINISTRATOR'];
const acceptTerminalsRole = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_MERCHANT_CONTROLLER',
  'ROLE_THYAGA_CUSTOMER_CARE',
];
const acceptEarningsRole = Object.values(EarningsRoles);

const acceptAddMerchantRole = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_CUSTOMER_CARE',
  'ROLE_THYAGA_MERCHANT_CONTROLLER',
];

const acceptPaymentsRole = ['ROLE_MERCHANT_ADMINISTRATOR'];
const acceptAddPaymentRole = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_ACCOUNTANT',
];

const acceptViewPurchases = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_CUSTOMER_CARE',
  'ROLE_THYAGA_ACCOUNTANT',
];
const acceptUserDetails = ['ROLE_THYAGA_ADMINISTRATOR'];
const acceptViewVouchers = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_CUSTOMER_CARE',
  'ROLE_THYAGA_ACCOUNTANT',
];
const acceptUserLogs = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_CUSTOMER_CARE',
];

const acceptDemoScreens = ['ROLE_THYAGA_DEMO'];

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
  },
  {
    name: 'User Details',
    path: '/user-details',
    acceptUserRole: acceptUserDetails,
    component: AuthorizedComponent(UserDetails, acceptUserDetails),
  },
  {
    name: 'Reward Management',
    path: '/reward-management',
    acceptUserRole: acceptDemoScreens,
    children: [
      {
        name: 'Manage Tiers',
        path: '/manage-tiers',
        component: AuthorizedComponent(ManageRatios, acceptDemoScreens),
      },
      {
        name: 'Loyalty Accounts',
        path: '/accounts',
        component: AuthorizedComponent(ViewVouchers, acceptDemoScreens),
      },
    ],
  },
  // {
  //   name: 'User Management',
  //   path: '/user-management',
  //   acceptUserRole: acceptDemoScreens,
  //   children: [
  //     {
  //       name: 'View Users',
  //       path: '/view-users',
  //       component: AuthorizedComponent(UserDetails, acceptDemoScreens),
  //     },
  //   ],
  // },
  {
    name: 'Redemptions',
    path: '/redemptions',
    acceptUserRole: acceptDemoScreens,
    children: [
      {
        name: 'View Redemptions',
        path: '/view-redemptions',
        component: AuthorizedComponent(ViewEarnings, acceptDemoScreens),
      },
    ],
  },
  {
    name: 'Reward Catalog',
    path: '/catalog',
    acceptUserRole: acceptDemoScreens,
    children: [
      {
        name: 'View Merchants',
        path: '/view-Merchants',
        component: AuthorizedComponent(ViewMerchants, acceptDemoScreens),
      },
      {
        name: 'Gift Inventory',
        path: '/gift-inventory',
        component: AuthorizedComponent(DenominationsSummary, acceptDemoScreens),
      },
      {
        name: 'View Offers',
        path: '/',
        component: AuthorizedComponent(ViewOffers, acceptDemoScreens),
      },
      {
        name: 'Add Offer',
        path: '/add-offer',
        component: AuthorizedComponent(AddOffer, acceptDemoScreens),
      },
      // {
      //   name: 'Add Merchant',
      //   path: '/add-Merchant',
      //   component: AuthorizedComponent(AddMerchant, acceptDemoScreens),
      // },
    ],
  },
  {
    name: 'Events',
    path: '/events',
    acceptUserRole: acceptDemoScreens,
    children: [
      {
        name: 'View Events',
        path: '/',
        component: AuthorizedComponent(ViewEvents, acceptDemoScreens),
      },
      {
        name: 'Add Events',
        path: '/add-events',
        component: AuthorizedComponent(AddEvents, acceptDemoScreens),
      },
      {
        name: 'View Execution Logs ',
        path: '/view-execution-logs',
        component: AuthorizedComponent(
          ViewExecutionLogScreen,
          acceptDemoScreens
        ),
      },
    ],
  },
  {
    name: 'Event Conditions',
    path: '/event-conditions',
    acceptUserRole: acceptDemoScreens,
    children: [
      {
        name: 'View Event Conditions',
        path: '/',
        component: AuthorizedComponent(ViewEventConditions, acceptDemoScreens),
      },
      {
        name: 'Add Event Conditions',
        path: '/add-event-conditions',
        component: AuthorizedComponent(AddEventConditions, acceptDemoScreens),
      },
    ],
  },

  {
    name: 'Extras',
    path: '/extras',
    acceptUserRole: acceptOfferRole,
    children: [
      {
        name: 'Awards',
        path: '/awards',
        component: AuthorizedComponent(WorkInProgress, acceptDemoScreens),
      },
      {
        name: 'Campaigns',
        path: '/campaigns',
        component: AuthorizedComponent(WorkInProgress, acceptDemoScreens),
      },
    ],
  },
  {
    name: 'Security Logs',
    path: '/security',
    acceptUserRole: acceptUserLogs,
    children: [
      {
        name: 'IP Logs',
        path: '/ip-logs',
        component: AuthorizedComponent(IPLogs, acceptUserLogs),
      },
      {
        name: 'OTP Logs',
        path: '/otp-logs',
        component: AuthorizedComponent(OTPLogs, acceptUserLogs),
      },
    ],
  },
  {
    name: 'Security Logs',
    path: '/security',
    acceptUserRole: acceptDemoScreens,
    children: [
      {
        name: 'IP Logs',
        path: '/',
        component: AuthorizedComponent(IPLogs, acceptDemoScreens),
      },
    ],
  },
  {
    name: 'Offers',
    path: '/offers',
    acceptUserRole: acceptOfferRole,
    children: [
      {
        name: 'View Offers',
        path: '/',
        component: AuthorizedComponent(ViewOffers, acceptOfferRole),
      },
      {
        name: 'Add Offer',
        path: '/add-offer',
        component: AuthorizedComponent(AddOffer, acceptOfferRole),
      },
    ],
  },

  {
    name: 'Terminals',
    path: '/terminals',
    acceptUserRole: acceptTerminalsRole,
    children: [
      {
        name: 'View Terminals',
        path: '',
        component: AuthorizedComponent(ViewTerminals, acceptTerminalsRole),
      },
      {
        name: 'Add Terminal',
        path: '/add-terminal',
        component: AuthorizedComponent(AddTerminal, acceptTerminalsRole),
      },
    ],
  },
  {
    name: 'Earnings',
    path: '/earnings',
    acceptUserRole: acceptEarningsRole,
    children: [
      {
        name: 'View Redemptions',
        path: '',
        component: AuthorizedComponent(ViewEarnings, acceptEarningsRole),
      },
    ],
  },
  {
    name: 'Payments',
    path: '/payments',
    acceptUserRole: acceptPaymentsRole,
    children: [
      {
        name: 'View Payments',
        path: '',
        component: AuthorizedComponent(ViewPayments, acceptPaymentsRole),
      },
    ],
  },
  {
    name: 'Payments',
    path: '/payments',
    acceptUserRole: acceptAddPaymentRole,
    children: [
      {
        name: 'View Payments',
        path: '/payments',
        component: AuthorizedComponent(ViewPayments, acceptAddPaymentRole),
      },
      {
        name: 'Add Payment',
        path: '/add-payment',
        component: AuthorizedComponent(AddPayment, acceptAddPaymentRole),
      },
    ],
  },

  {
    name: 'Purchases',
    path: '/purchases',
    acceptUserRole: acceptViewPurchases,
    children: [
      {
        name: 'View Purchases',
        path: '/view-Purchases',
        component: AuthorizedComponent(ViewPurchases, acceptViewPurchases),
      },
      {
        name: 'Pending Purchases',
        path: '/pending-Purchases',
        component: AuthorizedComponent(PendingPurchases, acceptViewPurchases),
      },
    ],
  },
  {
    name: 'Vouchers',
    path: '/vouchers',
    acceptUserRole: acceptViewVouchers,
    children: [
      {
        name: 'View Vouchers',
        path: '/view-vouchers',
        component: AuthorizedComponent(ViewVouchers, acceptViewVouchers),
      },
    ],
  },

  {
    name: 'Contact Us',
    path: '/contact-us',
    component: ContactUs,
  },
];

export default routes;
