import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { PageHeader, Pagination } from '../../components';
import CheckableDataTable from '../../components/CheckableDataTable/CheckableDataTable';
import useComponentState from '../../utils/useComponentState';
import { getAllOTPLogs, deleteOTPLogs, searchOTPLogs } from '../../api/OTPLogs';
import Search from '../../components/Search/Search.component';
import RowPaginator from '../../components/RowPaginator/RowPaginator';
import styles from './ManageRatios.module.scss';
import DataTable from '../../components/DataTable/DataTable';

const ManageRatios = (props) => {
  const [state, setState] = useComponentState({
    tableHeading: {
      column: [
        'Tier Name',
        'Description',
        'Minimum Points',
        'Maximum Points',
        'Accumulation Ratio',
        'Redemption Ratio',
      ],
    },
    multiplierHeading: {
      column: ['ID', 'Loyalty Tier', 'Multiplier'],
    },
    rewardRatios: [
      {
        id: 1,
        cardType: 'Rookie Fan',
        ppar: 0.01,
        per: 0.05,
        min: 'LKR 10,000',
        max: 'LKR 99,000',
      },
      {
        id: 2,
        cardType: 'Midfield Maestro',
        ppar: 0.01,
        per: 0.05,
        min: 'LKR 100,000',
        max: 'LKR 249,999',
      },
      {
        id: 3,
        cardType: 'Goal Scorer',
        ppar: 0.01,
        per: 0.05,
        min: 'LKR 250,000',
        max: 'LKR 499,999',
      },
      {
        id: 4,
        cardType: 'Captain’s Club',
        ppar: 0.01,
        per: 0.05,
        min: 'LKR 500,000',
        max: 'LKR 749,999',
      },
      {
        id: 5,
        cardType: 'Super Sub',
        ppar: 0.01,
        per: 0.05,
        min: 'LKR 750,000',
        max: 'LKR 999,999',
      },
      {
        id: 6,
        cardType: 'Hat-Trick Hero',
        ppar: 0.01,
        per: 0.05,
        min: 'LKR 100,000',
        max: 'LKR 2,000,000',
      },
      {
        id: 7,
        cardType: 'Hall of Fame',
        ppar: 0.01,
        per: 0.05,
        min: 'LKR 2,000,000',
        max: 'Above',
      },
      // { id: 6, cardType: 'Affinity', ppar: 0.01, per: 0.05 },
    ],
    tierMultipliers: [
      { id: 1, tier: 'Basic', multiplier: 0.1 },
      { id: 2, tier: 'Standard', multiplier: 0.15 },
      { id: 3, tier: 'Premium', multiplier: 0.2 },
      { id: 4, tier: 'Gold', multiplier: 0.25 },
      { id: 5, tier: 'Platinum', multiplier: 0.3 },
      // { id: 6, tier: 'Elite', multiplier: 0.4 },
    ],
    OTPPages: 0,
    curPage: 0,
    OTPBatchPages: 0,
    OTPSearchPages: 0,
    curBatchPage: 0,
    searchPage: 1,
    pageSize: 500,
    rowSize: 10,
    searchInput: '',
    isSelected: false,
    isApiCalled: false,
    receiverName: '',
    searchId: '',
    slicedOTPData: [],
    tableData: [],
    OTPLoadingError: '',
    loading: false,
    searchedMerchantMessage: '',
    selectedOTP: null,
    isClearable: false,
    checkedRows: [],
    selectAll: false,
    filteredData: [],
  });
  const {
    tableHeading,
    tableData,
    OTPData,
    curPage,
    curBatchPage,
    OTPBatchPages,
    searchInput,
    filteredData,
    slicedOTPData,
    OTPLoadingError,
    loading,
    OTPPages,
    rowSize,
    searchPage,
    searchedMerchantMessage,
    isClearable,
    pageSize,
    isSelected,
    isApiCalled,
    checkedRows,
    selectAll,
    rewardRatios,
    tierMultipliers,
    multiplierHeading,
  } = state;

  useEffect(() => {
    /* getOTPDetails(0); */
    getTierData(props.auth.auth.token);
    // eslint-disable-next-line
  }, []);

  const getTierData = async (token) => {
    setState({ loading: true });
    try {
      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/app/loyalty-tiers',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setState({
        tableData: data.loyaltyTiers,
        loading: false,
      });
    } catch (err) {
      console.error('Error fetching tier data:', err);

      setState({
        tableData: [],
        loading: false,
      });
    }
  };

  const getOTPDetails = async (page) => {
    setState({
      loading: true,
      OTPLoadingError: '',
      checkedRows: [],
    });
    try {
      const response = await getAllOTPLogs(
        props.auth.auth.token,
        page,
        pageSize
      );
      setState({
        OTPData: response.otpLogs,
        OTPBatchPages: response.totalPage,
        curBatchPage: response.pageNo /* OTPBatchPages: response */,
        isApiCalled: false,
      });
    } catch (error) {
      setState({
        OTPLoadingError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const searchOTPDetails = async (value, page) => {
    setState({
      loading: true,
      OTPLoadingError: '',
      checkedRows: [],
    });
    try {
      const response = await searchOTPLogs(
        props.auth.auth.token,
        value,
        page ? page + 1 : (page = 1),
        pageSize
      );
      setState({
        OTPData: response.otpLogs,
        OTPBatchPages: response.totalPage,
        isApiCalled: true,
      });
    } catch (error) {
      setState({
        OTPLoadingError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const handleDeleteOTPLogs = async () => {
    setState({
      loading: true,
    });
    try {
      const token = props.auth.auth.token;
      const response = await deleteOTPLogs(checkedRows, token);
      getOTPDetails(curBatchPage);
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  /* const handleOTPNameSearch = (name) => {
    if (name) {
      const filtered = OTPData.filter((data) => {
        if (data.recipient === null) {
          return false;
        }
        return data.recipient.toLowerCase().includes(name.toLowerCase());
      });
      setState({
        filteredData: filtered,
      });
    }
  }; */

  const searchOnChange = (value) => {
    if (/^\+?[0-9]*$/.test(value)) {
      setState({
        searchInput: value,
        isClearable: false,
        isApiCalled: false,
      });
    }
  };

  const handleOTPClear = () => {
    getOTPDetails(curBatchPage);
  };

  const handleRowPaginationChange = (event) => {
    setState({
      rowSize: event.target.value,
    });
  };

  const getOTPPages = (data) => {
    let OTPPages = 0;
    const mod = data.length % rowSize;
    if (mod > 0) {
      OTPPages = data.length / rowSize - mod / rowSize + 1;
    } else {
      OTPPages = data.length / rowSize;
    }
    return OTPPages;
  };

  /*const getOTPBatchPages = (data) => {
     let OTPBatchPages = 0;
    if (!data.hasOwnProperty('total')) {
      OTPBatchPages = 1;
    } else {
      OTPBatchPages = data.total;
    }
    return OTPBatchPages; 
  };*/

  const handlePagesPagination = (page) => {
    setState({ curPage: page });
    if (page > 0) {
      const sliceStart = page * rowSize;
      const sliceEnd = (page + 1) * rowSize;
      setState({
        slicedOTPData: OTPData.slice(sliceStart, sliceEnd),
      });
    } else {
      const sliceStart = 0;
      const sliceEnd = rowSize;
      setState({
        slicedOTPData: OTPData.slice(sliceStart, sliceEnd),
        checkedRows: [],
      });
    }
  };

  const handleBatchesPagination = (page) => {
    if (isApiCalled) {
      searchOTPDetails(searchInput, page);
    }
    getOTPDetails(page);
    setState({ curBatchPage: page });
  };

  const handleCheck = (rowId) => {
    if (checkedRows.includes(rowId)) {
      const updatedCheckedRows = checkedRows.filter((id) => id !== rowId);
      setState({ checkedRows: updatedCheckedRows });
      /* setCheckedRows(checkedRows.filter((id) => id !== rowId)); */
    } else {
      setState({ checkedRows: [...checkedRows, rowId] });
      /* setCheckedRows([...checkedRows, rowId]); */
    }
  };

  const handleSelectAll = (event) => {
    setState({ selectAll: event.target.checked });
    if (event.target.checked) {
      const selectedAll = slicedOTPData.map((data) => data.recipient);
      setState({ checkedRows: [...new Set([...checkedRows, ...selectedAll])] });
    } else {
      const updatedCheckedRows = checkedRows.filter(
        (id) => !slicedOTPData.some((data) => data.recipient === id)
      );
      setState({ checkedRows: updatedCheckedRows });
    }
  };

  return (
    <div className={styles.root_div}>
      <div>
        <PageHeader text='Manage Tiers' />
        {/*         <div className={styles.search_area_block}>
          <div className={styles.user_input}>
            <div></div>
            <RowPaginator
              handleRowPaginationChange={handleRowPaginationChange}
              rowSize={rowSize}
            />
            <div className={styles.right}>
              <div className={styles.search}>
                <Search
                  placeholder={'Mobile Number'}
                  // onBtnPress={handleOTPNameSearch}
                  onBtnPress={searchOTPDetails}
                  onClearPress={handleOTPClear}
                  onChange={searchOnChange}
                  validation={'mobileNo'}
                  searchInput={searchInput}
                  max={50}
                  handleValueFromParent
                  value={searchInput}
                />
              </div>
              <div className={styles.button_container}>
                <Button
                  onClick={handleDeleteOTPLogs}
                  className={styles.button}
                  variant='secondary'
                  // disabled={checkedRows.length === 0}
                >
                  Edit
                </Button>
                <Button
                  onClick={handleDeleteOTPLogs}
                  className={styles.button}
                  variant='primary'
                  // disabled={checkedRows.length === 0}
                >
                  Add
                </Button>
                <Button
                  onClick={handleDeleteOTPLogs}
                  className={styles.button}
                  variant='danger'
                  // disabled={checkedRows.length === 0}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div> */}
        <div>
          {/* <DataTable
            tableData={rewardRatios}
            tableheading={tableHeading}
            handleOnClick={() => {}}
            tag='rewardRatios'
            fetchError=''
          /> */}
          <div className=''>
            <div className='mr-4'>
              <div className={styles.search_area_block}>
                <div className={styles.user_input}>
                  <div></div>
                  <div className={styles.right}>
                    <div className={styles.search}></div>
                    <div className={styles.button_container}>
                      <Button
                        onClick={handleDeleteOTPLogs}
                        className={styles.button}
                        variant='secondary'
                        // disabled={checkedRows.length === 0}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={handleDeleteOTPLogs}
                        className={styles.button}
                        variant='primary'
                        // disabled={checkedRows.length === 0}
                      >
                        Add
                      </Button>
                      <Button
                        onClick={handleDeleteOTPLogs}
                        className={styles.button}
                        variant='danger'
                        // disabled={checkedRows.length === 0}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTable
                tableData={tableData}
                tableheading={tableHeading}
                handleOnClick={() => {}}
                tag='rewardRatios'
                fetchError=''
              />
            </div>
          </div>
          {/* {!OTPLoadingError && (
            <div className={styles.dualPaginateContainer}>
              <div className={styles.batches}>
                <Pagination
                  pageCount={OTPBatchPages}
                  onChange={handleBatchesPagination}
                  currentPage={0}
                />
                &nbsp;&nbsp;&nbsp;
                <span>
                  Batch {curBatchPage + 1} of {OTPBatchPages}
                </span>
              </div>
              <div className={styles.pages}>
                <span>
                  Page {curPage + 1} of {OTPPages}
                </span>
                &nbsp;&nbsp;&nbsp;
                <Pagination
                  pageCount={OTPPages}
                  onChange={handlePagesPagination}
                  currentPage={0}
                />
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(ManageRatios);

{
  /* <div style={{ flexGrow: 1 }}>
  <div className={styles.search_area_block}>
    <div className={styles.user_input}>
      <div></div>
      <div className={styles.right}>
        <div className={styles.search}></div>
        <div className={styles.button_container}>
          <Button
            onClick={handleDeleteOTPLogs}
            className={styles.button}
            variant='secondary'
            // disabled={checkedRows.length === 0}
          >
            Edit
          </Button>
          <Button
            onClick={handleDeleteOTPLogs}
            className={styles.button}
            variant='primary'
            // disabled={checkedRows.length === 0}
          >
            Add
          </Button>
          <Button
            onClick={handleDeleteOTPLogs}
            className={styles.button}
            variant='danger'
            // disabled={checkedRows.length === 0}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  </div>
  <DataTable
    tableData={tierMultipliers}
    tableheading={multiplierHeading}
    handleOnClick={() => {}}
    tag='tierMultipliers'
    fetchError=''
  />
</div>; */
}
