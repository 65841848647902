import React from 'react';

const WorkinProgress = () => {
  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
      Work In Progress
    </div>
  );
};

export default WorkinProgress;
