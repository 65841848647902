import React, { useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Select from 'react-select';
import { MoonLoader } from 'react-spinners';
import { FaCheckCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import { getSubMerchantsByMerchant } from '../../api/subMerchant';
import { createOffer } from '../../api/offer';
import {
  AppInput,
  AppButton,
  DatePicker,
  OfferTypeSelect,
  PageHeader,
} from '../../components';
import useComponentState from '../../utils/useComponentState';
import offerTypes from '../../constants/offerTypes';
import { getAllMerchant } from '../../api/merchant';

import CancelAlertPopup from '../../components/CancelAlertPopup/CancelAlertPopup';

import styles from './AddEvent.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { getAllTiers } from '../../api/events';
import { createEvent } from '@testing-library/react';

const AddEvents = (props) => {
  const [state, setState] = useComponentState({
    addOfferLoading: false,
    addOfferError: null,
    addOfferSuccess: false,
    showCancelPopup: false,
    eventName: '',
    description: '',
    triggeredAt: '',
    numberOfPointsOffered: 0,
    eventConditions: [],
    selectedEventConditions: [],
    tiers: [],
    selectedTiers: [],
  });

  const {
    auth: { token },
  } = props;
  const { addOfferLoading, addOfferError, addOfferSuccess } = state;

  const initialState = {
    eventName: '',
    description: '',
    triggeredAt: '',
    numberOfPointsOffered: null,
    selectedTiers: [],
    selectedEventConditions: [],
  };

  useEffect(() => {
    getTiers();
    getEventConditions();
    //eslint-disable-next-line
  }, []);

  const getTiers = async () => {
    try {
      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/app/loyalty-tiers',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setState({
          tiers: data.loyaltyTiers,
        });
      } else {
        throw new Error('Failed to fetch tiers');
      }
    } catch (err) {
      console.error('Error fetching tiers:', err);
      setState({ tiers: [] });
    }
  };

  const getEventConditions = async () => {
    try {
      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/event-condition',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setState({
          eventConditions: data.eventConditions,
        });
      } else {
        throw new Error('Failed to fetch event conditions');
      }
    } catch (err) {
      console.error('Error fetching event conditions:', err);
      setState({ eventConditions: [] });
    }
  };

  const handleAddEventSubmit = async () => {
    try {
      const payload = {
        name: state.eventName,
        description: state.description,
        triggeredAt: state.triggeredAt,
        numberOfPointsOffered: state.numberOfPointsOffered,
        tiersList: state.selectedTiers.map((tier) => tier.id),
        eventConditions: state.selectedEventConditions.map(
          (condition) => condition.id
        ),
      };

      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/event/add',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setState({
          addOfferLoading: false,
          eventName: '',
          description: '',
          triggeredAt: '',
          numberOfPointsOffered: null,
          selectedTiers: [],
          selectedEventConditions: [],
        });
        alert('Event added successfully');
        window.location.href = '/events';
      } else {
        throw new Error('Failed to add event');
      }
    } catch (err) {
      console.error('Error adding event:', err);
    }
  };

  const handleEventConditionSelect = (e) => {
    const selectedValue = e.target.value;
    const conditionObject = state.eventConditions.find(
      (condition) => condition.conditionKey === selectedValue
    );

    if (
      conditionObject &&
      !state.selectedEventConditions.some(
        (condition) => condition.id === conditionObject.id
      )
    ) {
      setState({
        selectedEventConditions: [
          ...state.selectedEventConditions,
          conditionObject,
        ],
      });
    }
  };

  const removeEventCondition = (conditionId) => {
    setState({
      selectedEventConditions: state.selectedEventConditions.filter(
        (condition) => condition.id !== conditionId
      ),
    });
  };

  const handleTierSelect = (e) => {
    const selectedValue = e.target.value;
    const tierObject = state.tiers.find((tier) => tier.name === selectedValue);

    if (
      tierObject &&
      !state.selectedTiers.some((tier) => tier.name === tierObject.name)
    ) {
      setState({ selectedTiers: [...state.selectedTiers, tierObject] });
    }
  };

  const removeTier = (tierName) => {
    setState({
      selectedTiers: state.selectedTiers.filter(
        (tier) => tier.name !== tierName
      ),
    });
  };

  return (
    <div className={styles.root__div}>
      <Formik
        initialValues={initialState}
        validationSchema={Yup.object().shape({
          eventName: Yup.string().required('Event name cannot be empty').trim(),
          description: Yup.string()
            .required('Description cannot be empty')
            .trim(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          // Your submit logic here
          handleAddEventSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isValid, dirty }) => {
          return (
            <div className={styles.form__div}>
              {addOfferLoading ? (
                <div className={styles.message__div}>
                  <MoonLoader size={30} color={'#3a195a'} />
                  <span className={styles.message__text}>Loading</span>
                </div>
              ) : addOfferSuccess ? (
                <div className={styles.message__div}>
                  <FaCheckCircle size={30} color={'#4da626'} />
                  <span className={styles.message__text}>
                    Offers added successfully
                  </span>
                </div>
              ) : (
                <Form className={styles.add__form}>
                  <PageHeader text='Add Event' />

                  <AppInput
                    name='eventName'
                    label='Event Name'
                    type='text'
                    placeholder='Enter Event Name'
                    style={{ borderRadius: '15px' }}
                  />

                  <AppInput
                    name='description'
                    label='Event Description'
                    type='text'
                    placeholder='Enter Event Description'
                    style={{ borderRadius: '15px' }}
                  />

                  {/* <AppInput
                    name='triggeredAt'
                    label='Triggered At'
                    type='text'
                    placeholder='Enter Trigger'
                    style={{ borderRadius: '15px' }}
                  /> */}

                  {/* Event Conditions */}
                  <div className={styles.form__group}>
                    <span className={styles.form__label}>Event Conditions</span>
                    <select
                      className='form-control form-control-sm custom-select'
                      onChange={handleEventConditionSelect}
                      value=''
                      style={{ borderRadius: '15px' }}
                    >
                      <option value='' disabled>
                        Select an Event Condition
                      </option>
                      {state.eventConditions.map((condition) => (
                        <option
                          key={condition.id}
                          value={condition.conditionKey}
                        >
                          {condition.conditionKey}
                        </option>
                      ))}
                    </select>

                    <div className={styles.selectedConditionsContainer}>
                      {state.selectedEventConditions.map((condition) => (
                        <span
                          key={condition.id}
                          className={styles.conditionChip}
                        >
                          {condition.conditionKey}
                          <button
                            type='button'
                            className={styles.removeButton}
                            onClick={() => removeEventCondition(condition.id)}
                          >
                            &times;
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>

                  {/* Tiers */}
                  <div className={styles.form__group}>
                    <span className={styles.form__label}>Tiers</span>
                    <select
                      className='form-control form-control-sm custom-select'
                      onChange={handleTierSelect}
                      value=''
                      style={{ borderRadius: '15px' }}
                    >
                      <option value='' disabled>
                        Select a Tier
                      </option>
                      {state.tiers.map((tier) => (
                        <option key={tier.name} value={tier.name}>
                          {tier.name}
                        </option>
                      ))}
                    </select>

                    <div className={styles.selectedTiersContainer}>
                      {state.selectedTiers.map((tier) => (
                        <span key={tier.name} className={styles.tierChip}>
                          {tier.name}
                          <button
                            type='button'
                            className={styles.removeButton}
                            onClick={() => removeTier(tier.name)}
                          >
                            &times;
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>

                  <AppInput
                    name='numberOfPointsOffered'
                    label='Number of Points Offered'
                    type='number'
                    placeholder='Enter Points to be Offered'
                    style={{ borderRadius: '15px' }}
                  />

                  <div
                    className={styles.btn_block}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                    }}
                  >
                    <AppButton
                      type='button'
                      text='Cancel'
                      onClick={() =>
                        setState({
                          showCancelPopup: true,
                          eventName: '',
                          description: '',
                          numberOfPointsOffered: null,
                          selectedTiers: [],
                          selectedEventConditions: [],
                        })
                      }
                      containerStyle={{ width: '50%' }}
                    />
                    <AppButton
                      type='submit'
                      text='Add Event'
                      disabled={!isValid || !dirty} // Disable button if form is invalid
                      containerStyle={{ width: '50%' }}
                    />
                  </div>

                  <CancelAlertPopup
                    show={state.showCancelPopup}
                    alertMessage='Are you sure you want to cancel?'
                    handleYesBtnClick={() => setState(initialState)}
                    handleNoBtnClick={() =>
                      setState({ showCancelPopup: false })
                    }
                  />
                </Form>
              )}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(AddEvents);
