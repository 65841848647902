import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ViewEvents.module.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Loading, PageHeader, Pagination } from '../../components';

import DataTable from '../../components/DataTable/DataTable';
import { getAllTerminals, saveNewPin } from '../../api/Terminal';
import { editEvent } from '../../api/events';
import CancelAlertPopup from '../../components/CancelAlertPopup/CancelAlertPopup';
import SearchByRedemtionIdOrReferanceCode from '../../components/SearchByRedemtionIdOrReferanceCode/SearchByRedemtionIdOrReferanceCode.component';

class ViewEvents extends Component {
  state = {
    tableheading: {
      header: 'EVENTS',
      column: [
        'Event ID',
        'Event Name',
        'Event Description',
        // 'Triggered At',
        'Point Offered',
      ],
    },

    showModal: false,
    onClickId: '',
    pin: '',
    tableData: [],
    slicedTableData: [],
    modalData: [],
    eventConditionData: [],
    tierData: [],
    loading: false,
    fetchError: '',
    pinSavingError: '',
    pinSavingSuccessMsg: '',
    terminalPages: 0,
    current: 0,
    editable: false,
    validated: false,
    status: 'Active',
    name: '',
    eventCondition: {},
    triggeredAt: '',
    tiersList: [],
    numberOfPointsOffered: null,
    description: '',
    pinType: 'password',
    rowData: null,
    cancelModalShow: false,
    btnClickable: false,
    pinError: null,
    canClearSearch: false,
    searchedMerchantName: '',
    notFoundMessage: null,
    changes: false,
    searchedTerminalId: '',
    data: [],
    selectedEventCondition: '',
  };

  componentDidMount() {
    const {
      auth: { token },
    } = this.props;

    this.setState({
      loading: false,
    });
    this.getEvents(token);
    this.getTierData(token);
    this.getEventConditionsData(token);
  }

  getEvents = async (token) => {
    try {
      const response = await fetch('https://dev-api.thyaga.xyz/v1.0/event', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      this.setState({
        tableData: data.events,
      });
    } catch (err) {
      console.error('Error fetching tiers:', err);

      this.setState({
        tableData: [],
      });
    }
  };

  getTierData = async (token) => {
    try {
      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/app/loyalty-tiers',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      this.setState({
        tierData: data.loyaltyTiers || [],
        loading: false,
      });
    } catch (err) {
      console.error('Error fetching tier data:', err);

      this.setState({
        tierData: [],
        loading: false,
      });
    }
  };

  getEventConditionsData = async (token) => {
    try {
      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/event-condition',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      this.setState({
        eventConditionData: data.eventConditions || [],
        loading: false,
      });
    } catch (err) {
      console.error('Error fetching event condition data:', err);

      this.setState({
        eventConditionData: [],
        loading: false,
      });
    }
  };

  calculateTerminalPage = (data) => {
    var terminalPages = 0;
    var mod = data.length % 10;
    if (mod > 0) {
      terminalPages = data.length / 10 - mod / 10 + 1;
    } else {
      terminalPages = data.length / 10;
    }
    return terminalPages;
  };

  sortTerminals = (data) => {
    return data.sort((a, b) =>
      a.merchant.name.toLowerCase() > b.merchant.name.toLowerCase() ? 1 : -1
    );
  };

  handlePagination = (page) => {
    let sliceStart = 0;
    let sliceEnd = 10;
    if (page > 0) {
      sliceStart = page * 10;
      sliceEnd = (page + 1) * 10;
    }
    this.setState({
      slicedTableData: this.state.tableData.slice(sliceStart, sliceEnd),
    });
  };

  handleModalShow = () => {
    this.setState({
      showModal: !this.state.showModal,
      editable: false,
      pinError: null,
      pinSavingSuccessMsg: '',
      pinSavingError: '',
      btnClickable: false,
      changes: false,
    });
  };

  handleCancelModalShow = () => {
    if (!this.state.changes) {
      this.setState({
        showModal: false,
        editable: false,
      });
    } else {
      this.setState({
        cancelModalShow: !this.state.cancelModalShow,
      });
    }
  };

  handleOnClick = (id) => {
    const rowData = this.state.tableData.find((data) => data.eventId === id);

    this.setState({
      showModal: true,
      onClickId: id,
      rowData,
      name: rowData.name,
      description: rowData.description,
      eventCondition: rowData.eventConditions,
      triggeredAt: rowData.triggeredAt,
      tiersList: rowData.tiersList,
      numberOfPointsOffered: rowData.numberOfPointsOffered,
    });
  };

  setModalFieldValues = (data) => {
    this.setState({
      name: data.name,
      eventCondition: data.eventCondition,
      triggeredAt: data.triggeredAt,
      tiersList: data.tiersList,
      numberOfPointsOffered: data.numberOfPointsOffered,
      description: data.additionalInfo,
    });
  };

  handlePinOnChange = (e) => {
    this.setState({
      pin: e.target.value.replace(/[^0-9\s]/g, '').trim(),
      btnClickable: true,
      pinError: null,
      changes: true,
    });
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: this.state[name].length === 0 ? value.trim() : value,
      btnClickable: true,
      changes: true,
    });
  };

  handleEditable = (boolean) => {
    this.setState({
      editable: boolean,
      pinSavingSuccessMsg: '',
      validated: false,
    });
    if (!boolean) {
      this.setModalFieldValues(this.state.rowData);
    } else {
      this.setState({
        pin: '',
      });
    }
  };

  handleCancelModalYesBtnClick = () => {
    this.setModalFieldValues(this.state.rowData);
    this.handleCancelModalShow();
    this.handleModalShow();
  };

  handleEditEvent = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      validated: true,
    });

    try {
      const payload = {
        id: this.state.onClickId,
        name: this.state.name,
        eventCondition: this.state.eventCondition.map(
          (condition) => condition.id
        ),
        triggeredAt: this.state.triggeredAt,
        tiersList: this.state.tiersList.map((tier) => tier.id),
        numberOfPointsOffered: this.state.numberOfPointsOffered,
        description: this.state.description,
      };

      const response = await fetch(
        'https://dev-api.thyaga.xyz/v1.0/event/update',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        this.setState({
          addOfferLoading: false,
          eventName: '',
          description: '',
          triggeredAt: '',
          numberOfPointsOffered: null,
          selectedTiers: [],
          selectedEventConditions: [],
        });
        alert('Event Updated successfully');
        window.location.href = '/events';
      }
    } catch (err) {
      this.setState({
        addOfferLoading: false,
        addOfferError: err.message,
      });
    }
  };

  handleStatusChange = (e) => {
    this.setState({
      status: e.target.value,
      btnClickable: true,
    });
  };

  onChangeMerchantNameChange = (e) => {
    this.setState({
      searchedMerchantName: e.target.value,
    });
  };

  onClean = () => {
    this.setState({
      searchedMerchantName: '',
      loading: true,
    });
    this.getEvents(this.props.auth.token);
  };

  terminalIdOnChange = (e) => {
    this.setState({
      searchedTerminalId: e.target.value,
    });
  };

  onEventIdClean = () => {
    this.setState({
      searchedTerminalId: '',
    });
    this.getEvents(this.props.auth.token);
  };

  removeEventCondition = (conditionId) => {
    this.setState({
      eventCondition: this.state.eventCondition.filter(
        (condition) => condition.id !== conditionId
      ),
    });
  };
  removeTier = (tierId) => {
    this.setState({
      tiersList: this.state.tiersList.filter((tier) => tier.id !== tierId),
    });
  };

  renderField = (type, name, value, onChange, max, label, required) => {
    return (
      <Form.Group>
        <Form.Control
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          maxLength={max}
          disabled={name === !this.state.editable}
          required={required}
          style={{ borderRadius: '15px' }}
        />
        <Form.Control.Feedback type='invalid' className='error_msg'>
          {label} cannot be empty
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  renderStatusDropDown = (defaultValue) => {
    return (
      <Form.Group controlId='Customvalidation' as={Row}>
        <Col>
          <select
            id='x-select'
            class='form-control form-control-sm custom-select'
            onClick={this.handleStatusChange}
            name='status'
            disabled={!this.state.editable}
            defaultValue={defaultValue}
          >
            <option value='Active' key={0}>
              Active
            </option>
            <option value='Inactive' key={1}>
              Inactive
            </option>
          </select>
        </Col>
      </Form.Group>
    );
  };

  renderInputGroup = (type, name, value, onChange, max, onClick) => {
    return (
      <Form.Group>
        <InputGroup>
          <Form.Control
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            required
            maxLength={max}
            disabled={'triggeredAt' || !this.state.editable}
          />
          <InputGroup.Append onClick={onClick}>
            <InputGroup.Text className='password-toggle__span'>
              <i
                className={type === 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}
              ></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {this.state.pinError && (
          <small className='error_msg'>{this.state.pinError}</small>
        )}
      </Form.Group>
    );
  };

  renderModalData = () => {
    const rowData = this.state;
    return (
      <>
        <tr>
          <td className={styles.fieldName}>Event Name</td>
          <td>
            {this.renderField(
              'text',
              'name',
              rowData.name,
              this.handleOnChange,
              100,
              'Name',
              false
            )}
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Description</td>
          <td>
            {this.renderField(
              'text',
              'description',
              rowData.description,
              this.handleOnChange,
              100,
              'Description',
              false
            )}
          </td>
        </tr>

        {/* <tr>
          <td className={styles.fieldName}>triggered At</td>
          <td>
            {this.renderField(
              'text',
              'triggeredAt',
              rowData.triggeredAt,
              this.handleOnChange,
              100,
              'Triggered At',
              false
            )}
          </td>
        </tr> */}

        <tr>
          <td className={styles.fieldName}>Event Conditions</td>
          <td>
            <Form.Group>
              <Form.Control
                as='select'
                style={{ borderRadius: '15px' }}
                value={this.state.selectedEventCondition || ''} // Controlled state for the dropdown
                onChange={(e) => {
                  const selectedCondition = this.state.eventConditionData.find(
                    (cond) => cond.id === parseInt(e.target.value, 10)
                  );
                  if (selectedCondition) {
                    // Check if the condition already exists
                    const alreadyExists = this.state.eventCondition.some(
                      (cond) => cond.id === selectedCondition.id
                    );
                    if (!alreadyExists) {
                      this.setState((prevState) => ({
                        eventCondition: [
                          ...prevState.eventCondition,
                          selectedCondition,
                        ],
                        selectedEventCondition: '', // Clear the dropdown selection
                      }));
                    } else {
                      alert('This event condition is already added.');
                    }
                  }
                }}
              >
                <option value=''>Add more event conditions</option>
                {this.state.eventConditionData.map((condition) => (
                  <option key={condition.id} value={condition.id}>
                    {condition.conditionKey} - {condition.operator}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <div className={styles.selectedConditionsContainer}>
              {this.state.eventCondition &&
              Array.isArray(this.state.eventCondition) ? (
                this.state.eventCondition.map((condition, index) => (
                  <span key={index} className={styles.conditionChip}>
                    {condition.conditionKey} - {condition.operator} -{' '}
                    {condition.value}
                    <button
                      type='button'
                      className={styles.removeButton}
                      onClick={() => this.removeEventCondition(condition.id)}
                    >
                      &times;
                    </button>
                  </span>
                ))
              ) : (
                <span className={styles.conditionChip}>No Conditions </span>
              )}
            </div>
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Tiers</td>
          <td>
            <Form.Group>
              <Form.Control
                style={{ borderRadius: '15px' }}
                as='select'
                value={this.state.selectedTier || ''} // Controlled state for the dropdown
                onChange={(e) => {
                  const selectedTier = this.state.tierData.find(
                    (tier) => tier.id === parseInt(e.target.value, 10)
                  );
                  if (selectedTier) {
                    // Check if the tier already exists
                    const alreadyExists = this.state.tiersList.some(
                      (tier) => tier.id === selectedTier.id
                    );
                    if (!alreadyExists) {
                      this.setState((prevState) => ({
                        tiersList: [...prevState.tiersList, selectedTier],
                        selectedTier: '', // Clear the dropdown selection
                      }));
                    } else {
                      alert('This tier is already added.');
                    }
                  }
                }}
              >
                <option value=''>Add more tiers</option>
                {this.state.tierData.map((tier) => (
                  <option key={tier.id} value={tier.id}>
                    {tier.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <div className={styles.selectedTierContainer}>
              {this.state.tiersList && Array.isArray(this.state.tiersList)
                ? this.state.tiersList.map((tier, index) => (
                    <span key={index} className={styles.tierChip}>
                      {tier.name}
                      <button
                        type='button'
                        className={styles.removeButton}
                        onClick={() => this.removeTier(tier.id)}
                      >
                        &times;
                      </button>
                    </span>
                  ))
                : 'No Tiers'}
            </div>
          </td>
        </tr>

        <tr>
          <td className={styles.fieldName}>Points Offered</td>
          <td>
            {this.renderField(
              'number',
              'numberOfPointsOffered',
              rowData.numberOfPointsOffered,
              this.handleOnChange,
              10,
              'Point Offered',
              false
            )}
          </td>
        </tr>
      </>
    );
  };

  renderModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showModal}
          onHide={this.handleModalShow}
          backdrop='static'
          keyboard={false}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Event ID : {this.state.onClickId}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <div className={styles.edit_btn}>
              <Button
                variant='danger'
                onClick={() => {
                  this.handleEditable(true);
                }}
              >
                Edit
              </Button>
            </div> */}
            <div className={styles.modal_root}>
              <Form
                noValidate
                validated={this.state.validated}
                onSubmit={this.handleEditEvent}
              >
                <Table borderless>
                  <tbody>{this.renderModalData()}</tbody>
                </Table>
                <div className={styles.modal_footer}>
                  <Button
                    variant='primary'
                    type='submit'
                    disabled={!this.state.btnClickable}
                    block
                    className={styles.footer_btn}
                    style={{
                      borderRadius: '15px',
                      backgroundColor: '#210842',
                      borderColor: '#fff',
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    variant='secondary'
                    onClick={this.handleCancelModalShow}
                    block
                    className={styles.footer_btn}
                    style={{ borderRadius: '15px' }}
                  >
                    Close
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer />
          <Modal.Body className={styles.modal_footer_msg}>
            {this.state.pinSavingSuccessMsg !== '' ? (
              <p className={styles.success_msg}>
                {this.state.pinSavingSuccessMsg}
              </p>
            ) : this.state.pinSavingError !== '' ? (
              <p className={styles.error_msg}>{this.state.pinSavingError}</p>
            ) : null}
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  renderLoading = () => {
    return <Loading text='Loading' />;
  };

  renderError = () => {
    return <div>{this.state.error}</div>;
  };

  render() {
    return (
      <div className={styles.root__div}>
        {this.state.loading ? (
          <Loading text='Loading' />
        ) : (
          <div className={styles.table}>
            <PageHeader text='Events' />
            <div className={styles.search_area}>
              <SearchByRedemtionIdOrReferanceCode
                onSubmit={this.handleSearchByMerchant}
                onChange={this.onChangeMerchantNameChange}
                value={this.state.searchedMerchantName}
                placeholder='Event Name'
                isClearable={this.state.canClearSearch}
                onClean={this.onClean}
              />
              <div className={styles.separator} />
              <SearchByRedemtionIdOrReferanceCode
                onSubmit={this.handleSearchByTerminalId}
                onChange={this.terminalIdOnChange}
                value={this.state.searchedTerminalId}
                placeholder='Event ID'
                onClean={this.onEventIdClean}
              />
              <div className={styles.separator} />
            </div>
            <div className={styles.table_div}>
              <DataTable
                tableData={this.state.tableData}
                tableheading={this.state.tableheading}
                handleOnClick={this.handleOnClick}
                tag='events'
                fetchError={this.state.fetchError}
                notFoundMessage={this.state.notFoundMessage}
              />
            </div>
          </div>
        )}

        {this.state.tableData.length > 10 && (
          <div className={styles.pager}>
            <Pagination
              pageCount={this.state.terminalPages}
              onChange={this.handlePagination}
              currentPage={this.state.current}
            />
          </div>
        )}

        {this.renderModal()}

        <CancelAlertPopup
          show={this.state.cancelModalShow}
          alertMessage='Your details will be lost. Do you wish to proceed?'
          handleYesBtnClick={this.handleCancelModalYesBtnClick}
          handleNoBtnClick={this.handleCancelModalShow}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewEvents);
